import { useCallback, useEffect, useMemo } from 'react';

/**
 * A shorthand for useCallback with an empty dependency array.
 * @param callback The callback function to memoize.
 * @returns The memoized callback.
 */
function useCallbackOnce(callback) {
    return useCallback(callback, []);
}
/**
 * A shorthand for useEffect with an empty dependency array.
 * @param effect The effect function to run.
 */
function useEffectOnce(effect) {
    useEffect(effect, []);
}
/**
 * A shorthand for useMemo with an empty dependency array.
 * @param factory The factory function to create the value.
 * @returns The memoized value.
 */
function useMemoOnce(factory) {
    return useMemo(factory, []);
}

export { useCallbackOnce, useEffectOnce, useMemoOnce };
