import { useState } from 'react';
import 'value-or-factory';
import 'react/jsx-runtime';
import 'dequal';
import { useCallbackOnce } from './once.mjs';

/**
 * Returns a boolean state object.
 * @param initialValue An initial boolean value.
 * @returns A boolean state object.
 */
function useBoolean(initialValue = false) {
    const [value, set] = useState(initialValue);
    const on = useCallbackOnce(() => set(true));
    const off = useCallbackOnce(() => set(false));
    const toggle = useCallbackOnce(() => set(state => !state));
    return { value, on, off, toggle, set };
}

export { useBoolean };
