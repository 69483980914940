
import { GRAPHQL_URL, SYNC_LOCK } from "config"
import { Kysely } from "kysely"
import { Schema } from "services/db/Schema"
import FileStore from "services/store/FileStore"
import FileSyncer from "services/sync/FileSyncer"
import ObjectSyncer from "services/sync/ObjectSyncer"
import * as GenQLSync from "shared/genql/sync"
import { isTemporaryException } from "shared/http"
import { promiseChain } from "shared/misc"
import { Hasura } from "ui/services/Hasura"

export type SyncStats = readonly (readonly [string, number])[]

export default class {

    private readonly objectSyncer
    private readonly fileSyncer

    constructor(db: Kysely<unknown>,
        fileStore: FileStore,
        readonly token: string,
        userId: number) {
        const hasura = Hasura.build(GRAPHQL_URL, GenQLSync, token, "sync")
        this.objectSyncer = new ObjectSyncer(db, hasura, userId)
        this.fileSyncer = new FileSyncer(db.withTables<Schema>(), hasura, fileStore)
    }

    async sync(): Promise<SyncStats> {
        const broadcast = new BroadcastChannel(SYNC_LOCK)
        try {
            return await navigator.locks.request(SYNC_LOCK, async () => {
                broadcast.postMessage(void 0)
                try {
                    const started = Date.now()
                    console.info("[Sync] Starting a sync session...")
                    const tasks = {
                        prepare: () => this.objectSyncer.prepare(),
                        objectUpload: () => this.objectSyncer.upload(),
                        objectDownload: () => this.objectSyncer.download(),
                        fileUpload: () => this.fileSyncer.upload(),
                        fileDownload: () => this.fileSyncer.download(),
                        fileClean: () => this.fileSyncer.clean()
                    }
                    const parts = await promiseChain(Object.entries(tasks).map(([name, task]) => {
                        return async () => {
                            console.log("[Sync] Doing sync part: " + name + ".")
                            const started = performance.now()
                            const stat = Object.entries(await task())
                            const elapsed = performance.now() - started
                            console.log("[Sync] Completed sync part: " + name + " in " + elapsed + "ms.", stat)
                            return [
                                ...stat,
                                [name + "Elapsed", elapsed] as const
                            ]
                        }
                    }))
                    const stats = [
                        ...parts,
                        ["elapsed", Date.now() - started] as const,
                    ]
                    console.info("[Sync] Completed a sync, see stats attached.", stats)
                    return stats
                }
                catch (e) {
                    if (!isTemporaryException(e)) {
                        console.error("[Sync] Sync session failed.", e)
                    }
                    throw e
                }
            })
        }
        finally {
            broadcast.close()
        }
    }

}
