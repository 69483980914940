import { flow } from 'fp-ts/function';
import { callOrGet } from 'value-or-factory';
import { buildError } from './base.mjs';
import { flatMap, failure, of } from './core.mjs';
import { exec } from './exec.mjs';
import { tryCatch } from './try-catch.mjs';

/**
 * Require a value.
 * @param message An optional custom error message.
 * @returns A mapper that returns a value or error if undefined.
 */
const req = (error = "This value is required") => flatMap(value => {
    if (value === undefined || value === null) {
        return failure(buildError(error, value));
    }
    return of(value);
});
/**
 * Creates a mapper that returns a default value if the input is null or undefined.
 * @param factory Default generator or value.
 * @returns A new mapper.
 */
const or = (factory) => tryCatch(value => {
    if (value === undefined || value === null) {
        return callOrGet(factory);
    }
    return value;
});
const orUndefined = (result) => or(undefined)(result);
const orNull = (result) => or(null)(result);
/**
 * Wraps a mapper so that it skips empty values (null or undefined).
 * @param mapper Mapper
 * @returns New mapper that skips empty values.
 */
const maybe = (mapper) => flatMap(value => {
    if (value === undefined) {
        return of(undefined);
    }
    if (value === null) {
        return of(null);
    }
    return exec(value, mapper);
});
const maybeOr = (mapper, factory) => flow(maybe(mapper), or(factory));
const maybeOrNull = (mapper) => flow(maybe(mapper), orNull);
const maybeOrUndefined = (mapper) => flow(maybe(mapper), orUndefined);

export { maybe, maybeOr, maybeOrNull, maybeOrUndefined, or, orNull, orUndefined, req };
