/**
 * Generates an error using a factory and a value.
 */
function buildError(factory, value) {
    if (typeof factory === "function") {
        return buildError(factory(value), value);
    }
    else if (Array.isArray(factory)) {
        return factory.map(_ => buildError(_, value)).flat();
    }
    else if (typeof factory === "string") {
        return [{ path: [], message: factory, value }];
    }
    else if (factory === undefined) {
        return [];
    }
    else {
        return [{ ...factory, value }];
    }
}

export { buildError };
