import { useMemo } from 'react';
import { useCustomCompareMemo } from './custom-compare.mjs';
import { useDeepCompareMemo } from './deep-compare.mjs';
import { useMemoOnce } from './once.mjs';

/**
 * Creates a new instance of a class.
 * @param factory The class constructor.
 * @returns The new instance of the class.
 */
function useConstructorOnce(factory) {
    return useMemoOnce(() => new factory());
}
/**
 * Creates a new instance of a class when the dependencies change.
 * @param factory The class constructor.
 * @param deps The dependencies.
 * @returns The new instance of the class.
 */
function useConstructor(factory, deps) {
    return useMemo(() => new factory(...deps), deps);
}
/**
 * Creates a new instance of a class when the dependencies change.
 * @param factory The class constructor.
 * @param deps The dependencies.
 * @returns The new instance of the class.
 */
function useCustomCompareConstructor(factory, deps, depsAreEqual) {
    return useCustomCompareMemo(() => new factory(...deps), deps, depsAreEqual);
}
/**
 * Creates a new instance of a class when the dependencies change.
 * @param factory The class constructor.
 * @param deps The dependencies.
 * @returns The new instance of the class.
 */
function useDeepCompareConstructor(factory, deps) {
    return useDeepCompareMemo(() => new factory(...deps), deps);
}

export { useConstructor, useConstructorOnce, useCustomCompareConstructor, useDeepCompareConstructor };
