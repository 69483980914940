import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import { CellClickedEvent, CellDoubleClickedEvent } from "@ag-grid-community/core"
import { CsvExportModule } from "@ag-grid-community/csv-export"
import { AdvancedFilterModule } from "@ag-grid-enterprise/advanced-filter"
import { ClipboardModule } from "@ag-grid-enterprise/clipboard"
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel"
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail"
import { MenuModule } from "@ag-grid-enterprise/menu"
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection"
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping"
import { SetFilterModule } from "@ag-grid-enterprise/set-filter"
import { StatusBarModule } from "@ag-grid-enterprise/status-bar"
import { processCellExport, processCellImport } from "adapters/grid"
import { GridPlugin } from "adapters/grid/plugins"
import { useCallback } from "react"
import { isIOS, isMobile } from 'react-device-detect'
import { GRID_COLUMN_MIN_WIDTH } from "ui/config"

export default function <R>(): GridPlugin<R> {
    const onCellClicked = useCallback((event: CellClickedEvent<R>) => {
        if (isMobile && event.colDef.cellEditor === undefined) {
            if (event.column.isCellEditable(event.node)) {
                if (event.node.rowIndex === null) {
                    return
                }
                event.api.startEditingCell({
                    rowIndex: event.node.rowIndex,
                    colKey: event.column.getId(),
                })
            }
        }
    }, [])
    const onCellDoubleClicked = useCallback((event: CellDoubleClickedEvent<R>) => {
        if (event.colDef.cellEditor === undefined) {
            if (event.column.isCellEditable(event.node)) {
                if (event.node.rowIndex === null) {
                    return
                }
                event.api.startEditingCell({
                    rowIndex: event.node.rowIndex,
                    colKey: event.column.getId(),
                })
            }
        }
    }, [])
    return options => {
        return {
            defaultColDef: {
                filter: "agTextColumnFilter",
                floatingFilter: true,
                sortable: true,
                resizable: true,
                minWidth: GRID_COLUMN_MIN_WIDTH,
                filterParams: {
                    newRowsAction: "keep"
                },
                onCellClicked: onCellClicked,
                onCellDoubleClicked: onCellDoubleClicked,
            },
            modules: [
                ClientSideRowModelModule,
                CsvExportModule,
                ClipboardModule,
                ColumnsToolPanelModule,
                MasterDetailModule,
                MenuModule,
                RangeSelectionModule,
                RowGroupingModule,
                SetFilterModule,
                StatusBarModule,
                AdvancedFilterModule
            ],
            divClassName: isIOS ? "ag-grid-manual" : "ag-grid",// pb-" + STANDARD_SPACING,
            domLayout: "normal",
            navCollapse: "lg",
            rowBuffer: 0,
            maintainColumnOrder: true,
            className: "ag-theme-alpine",
            enableRangeSelection: true,
            animateRows: true,
            suppressRowHoverHighlight: true,
            enterMovesDown: false,
            //debug: IS_TESTING,
            //suppressMaintainUnsortedOrder: true,
            //rowGroupPanelShow: "always",
            enterNavigatesVertically: true,
            suppressRowClickSelection: true,
            suppressLastEmptyLineOnPaste: true,
            multiSortKey: "ctrl",
            //singleClickEdit: isMobile,
            accentedSort: true,
            getContextMenuItems: () => ["copy", "copyWithHeaders"],
            processCellForClipboard: processCellExport,
            processCellFromClipboard: processCellImport,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    }
                ]
            },
            ...options
        }
    }
}
