import { ApolloClient, gql } from "@apollo/client"
import { pascalCase } from "change-case"
import { PG } from "config"
import { jsonToGraphQLQuery } from "json-to-graphql-query"
import { Kysely } from "kysely"
import { Ramda } from "namespaces/Ramda"
import PLazy from "p-lazy"
import { SYNC_TABLES, SqliteTables } from "services/db/Schema"

export type Introspection = Awaited<ReturnType<Introspector["forceIntrospect"]>>
export type IntrospectionTable = Introspection[number]
export type IntrospectionColumn = IntrospectionTable["columns"][number]

export default class Introspector {

    static readonly METAS = ["isDirty"]

    readonly introspection

    constructor(private readonly db: Kysely<unknown>, private readonly apollo: ApolloClient<unknown>) {
        this.introspection = PLazy.from(() => this.forceIntrospect())
    }

    private async forceIntrospect() {
        const db = this.db
        if (PG) {
            return [
                {
                    "name": "uploads",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "uploads",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "uploads",
                            "select": "name",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "type",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "uploads",
                            "select": "type",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "size",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "size",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "present",
                            "pk": 0,
                            "type": "boolean",
                            "table": "uploads",
                            "select": "present",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "resized",
                            "pk": 0,
                            "type": "boolean",
                            "table": "uploads",
                            "select": "resized",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "width",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "width",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "height",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "height",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "uploads",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "uploads",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "uploads",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "uploads",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "uploads",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "templates",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "templates",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "templates",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "templates",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "html",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "templates",
                            "select": "html",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "css",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "templates",
                            "select": "css",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "templates",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "templates",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "templates",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "templates",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "templates",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "templates",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "type",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "templates",
                            "select": "type",
                            "insert": true,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "assetTypes",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "assetTypes",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "assetTypes",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assetTypes",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "assetTypes",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assetTypes",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assetTypes",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "assetTypes",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "assetTypes",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assetTypes",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "statuses",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "statuses",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "statuses",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "statuses",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "color",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "statuses",
                            "select": "color",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "public",
                            "pk": 0,
                            "type": "boolean",
                            "table": "statuses",
                            "select": "public",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "statuses",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "statuses",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "statuses",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "statuses",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "statuses",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "statuses",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "severities",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "severities",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "severities",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "severities",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "color",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "severities",
                            "select": "color",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "severities",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "severities",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "severities",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "severities",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "severities",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "severities",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "procedureTypes",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "procedureTypes",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "procedureTypes",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedureTypes",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedureTypes",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTypes",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTypes",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureTypes",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureTypes",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTypes",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspectionReasons",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "inspectionReasons",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "inspectionReasons",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionReasons",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "description",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionReasons",
                            "select": "description",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspectionReasons",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionReasons",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionReasons",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionReasons",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionReasons",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionReasons",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "energySources",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "energySources",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "energySources",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "energySources",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "prefix",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "energySources",
                            "select": "prefix",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "color",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "energySources",
                            "select": "color",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "iconId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "energySources",
                            "select": "iconId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "energySources",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energySources",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energySources",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "energySources",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "energySources",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energySources",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "energyMeasures",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 2,
                            "type": "uuid",
                            "table": "energyMeasures",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 1,
                            "type": "INTEGER",
                            "table": "energyMeasures",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "energySourceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "energyMeasures",
                            "select": "energySourceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "energyMeasures",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "energyMeasures",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energyMeasures",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energyMeasures",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "energyMeasures",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "energyMeasures",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "energyMeasures",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "isolationDevices",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "isolationDevices",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "isolationDevices",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "energySourceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "isolationDevices",
                            "select": "energySourceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "isolationDevices",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "methodStatement",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "isolationDevices",
                            "select": "methodStatement",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "verificationStatement",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "isolationDevices",
                            "select": "verificationStatement",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "iconId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "isolationDevices",
                            "select": "iconId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "videoId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "isolationDevices",
                            "select": "videoId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "isolationDevices",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "isolationDevices",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "isolationDevices",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "isolationDevices",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "isolationDevices",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "isolationDevices",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "hazards",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "hazards",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "hazards",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "hazards",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "statement",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "hazards",
                            "select": "statement",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "color",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "hazards",
                            "select": "color",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "iconId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "hazards",
                            "select": "iconId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "hazards",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "hazards",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "hazards",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "hazards",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "hazards",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "hazards",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "customFields",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "customFields",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "customFields",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "subject",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "subject",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "type",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "type",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "required",
                            "pk": 0,
                            "type": "boolean",
                            "table": "customFields",
                            "select": "required",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "regex",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "regex",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "choices",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "choices",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "fileTypes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "customFields",
                            "select": "fileTypes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "hide",
                            "pk": 0,
                            "type": "boolean",
                            "table": "customFields",
                            "select": "hide",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "customFields",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "customFields",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "customFields",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "customFields",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "customFields",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "customFields",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "procedureTemplates",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "procedureTemplates",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "procedureTemplates",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedureTemplates",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "description",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedureTemplates",
                            "select": "description",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "showInMenu",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedureTemplates",
                            "select": "showInMenu",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "typeId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "procedureTemplates",
                            "select": "typeId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "label",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedureTemplates",
                            "select": "label",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedureTemplates",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "json",
                            "table": "procedureTemplates",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedureTemplates",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTemplates",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTemplates",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureTemplates",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureTemplates",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureTemplates",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "controlPointTemplates",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "controlPointTemplates",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "procedureTemplateId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "procedureTemplateId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "photoId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "photoId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "barCode",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPointTemplates",
                            "select": "barCode",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "step",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPointTemplates",
                            "select": "step",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "number",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPointTemplates",
                            "select": "number",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "isolationLocation",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPointTemplates",
                            "select": "isolationLocation",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energyMagnitude",
                            "pk": 0,
                            "type": "REAL",
                            "table": "controlPointTemplates",
                            "select": "energyMagnitude",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energyMeasureId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "energyMeasureId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "isolationDeviceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "isolationDeviceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energySourceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPointTemplates",
                            "select": "energySourceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "methodOverride",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPointTemplates",
                            "select": "methodOverride",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "verificationOverride",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPointTemplates",
                            "select": "verificationOverride",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "json",
                            "table": "controlPointTemplates",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "controlPointTemplates",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPointTemplates",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPointTemplates",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPointTemplates",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPointTemplates",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPointTemplates",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "companySettings",
                    "columns": [
                        {
                            "name": "companyId",
                            "pk": 1,
                            "type": "INTEGER",
                            "table": "companySettings",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "defaultStatusId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "companySettings",
                            "select": "defaultStatusId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "defaultTemplateId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "companySettings",
                            "select": "defaultTemplateId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "companySettings",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "companySettings",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "companySettings",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "companySettings",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "companySettings",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "locations",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "locations",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "locations",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "locations",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "jsonb",
                            "table": "locations",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "locations",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "locations",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "locations",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "locations",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "locations",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "locations",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "address",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "locations",
                            "select": "address",
                            "insert": true,
                            "update": true
                        }
                    ]
                },
                {
                    "name": "assets",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "assets",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "assets",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "locationId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "assets",
                            "select": "locationId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "serial",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "serial",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "typeId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "assets",
                            "select": "typeId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "photoId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "assets",
                            "select": "photoId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "pin",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "pin",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "barCode",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "barCode",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "jsonb",
                            "table": "assets",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "assets",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assets",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assets",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "assets",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "assets",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "assets",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "manufacturer",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "manufacturer",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "model",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "assets",
                            "select": "model",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "voltage",
                            "pk": 0,
                            "type": "decimal",
                            "table": "assets",
                            "select": "voltage",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "ratedAmperage",
                            "pk": 0,
                            "type": "decimal",
                            "table": "assets",
                            "select": "ratedAmperage",
                            "insert": true,
                            "update": true
                        }
                    ]
                },
                {
                    "name": "procedures",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "procedures",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "procedures",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "assetId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "procedures",
                            "select": "assetId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "statusId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "procedures",
                            "select": "statusId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "label",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedures",
                            "select": "label",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "typeId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "procedures",
                            "select": "typeId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "procedures",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "jsonb",
                            "table": "procedures",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedures",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedures",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedures",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedures",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedures",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedures",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        },
                        {
                            "name": "fromAudit",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedures",
                            "select": "fromAudit",
                            "insert": true,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "procedureHazards",
                    "columns": [
                        {
                            "name": "companyId",
                            "pk": 1,
                            "type": "INTEGER",
                            "table": "procedureHazards",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "procedureId",
                            "pk": 2,
                            "type": "uuid",
                            "table": "procedureHazards",
                            "select": "procedureId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "hazardId",
                            "pk": 3,
                            "type": "uuid",
                            "table": "procedureHazards",
                            "select": "hazardId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "procedureHazards",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureHazards",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureHazards",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureHazards",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "procedureHazards",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "procedureHazards",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "controlPoints",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "controlPoints",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "procedureId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "procedureId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "photoId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "photoId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "barCode",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPoints",
                            "select": "barCode",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "step",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPoints",
                            "select": "step",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "number",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPoints",
                            "select": "number",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energyMagnitude",
                            "pk": 0,
                            "type": "REAL",
                            "table": "controlPoints",
                            "select": "energyMagnitude",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energySourceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "energySourceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "energyMeasureId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "energyMeasureId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "isolationDeviceId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "controlPoints",
                            "select": "isolationDeviceId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "isolationLocation",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPoints",
                            "select": "isolationLocation",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "methodOverride",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPoints",
                            "select": "methodOverride",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "verificationOverride",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "controlPoints",
                            "select": "verificationOverride",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "customFields",
                            "pk": 0,
                            "type": "jsonb",
                            "table": "controlPoints",
                            "select": "customFields",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "controlPoints",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPoints",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPoints",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPoints",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "controlPoints",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "controlPoints",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "languages",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "languages",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "languages",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "name",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "languages",
                            "select": "name",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "languages",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "languages",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "languages",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "languages",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "languages",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "languages",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "translations",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "translations",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "translations",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "languageId",
                            "pk": 3,
                            "type": "uuid",
                            "table": "translations",
                            "select": "languageId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "pattern",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "translations",
                            "select": "pattern",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "replacement",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "translations",
                            "select": "replacement",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "translations",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "translations",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "translations",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "translations",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "translations",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "translations",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspections",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "inspections",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "inspections",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "locationId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspections",
                            "select": "locationId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "label",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspections",
                            "select": "label",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "active",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspections",
                            "select": "active",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspections",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspections",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspections",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspections",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspections",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspections",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspectionItems",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "inspectionItems",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "inspectionItems",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "inspectionId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspectionItems",
                            "select": "inspectionId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "assetId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspectionItems",
                            "select": "assetId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "amperageA",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionItems",
                            "select": "amperageA",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "amperageB",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionItems",
                            "select": "amperageB",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "amperageC",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionItems",
                            "select": "amperageC",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "amperageN",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionItems",
                            "select": "amperageN",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "status",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionItems",
                            "select": "status",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionItems",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspectionItems",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItems",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItems",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionItems",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionItems",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItems",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspectionItemPhotos",
                    "columns": [
                        {
                            "name": "companyId",
                            "pk": 1,
                            "type": "INTEGER",
                            "table": "inspectionItemPhotos",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "inspectionItemId",
                            "pk": 2,
                            "type": "uuid",
                            "table": "inspectionItemPhotos",
                            "select": "inspectionItemId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "photoId",
                            "pk": 3,
                            "type": "uuid",
                            "table": "inspectionItemPhotos",
                            "select": "photoId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspectionItemPhotos",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItemPhotos",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItemPhotos",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionItemPhotos",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionItemPhotos",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionItemPhotos",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspectionFindings",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "inspectionFindings",
                            "select": "id",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "inspectionFindings",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "inspectionItemId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspectionFindings",
                            "select": "inspectionItemId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "reasonId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspectionFindings",
                            "select": "reasonId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "severityId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "inspectionFindings",
                            "select": "severityId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "phase",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionFindings",
                            "select": "phase",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "circuit",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionFindings",
                            "select": "circuit",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "temperatureHigh",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionFindings",
                            "select": "temperatureHigh",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "temperatureAmbient",
                            "pk": 0,
                            "type": "decimal",
                            "table": "inspectionFindings",
                            "select": "temperatureAmbient",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "inspectionFindings",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspectionFindings",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindings",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindings",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionFindings",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionFindings",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindings",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "inspectionFindingPhotos",
                    "columns": [
                        {
                            "name": "companyId",
                            "pk": 1,
                            "type": "INTEGER",
                            "table": "inspectionFindingPhotos",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "inspectionFindingId",
                            "pk": 2,
                            "type": "uuid",
                            "table": "inspectionFindingPhotos",
                            "select": "inspectionFindingId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "photoId",
                            "pk": 3,
                            "type": "uuid",
                            "table": "inspectionFindingPhotos",
                            "select": "photoId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "inspectionFindingPhotos",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindingPhotos",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindingPhotos",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionFindingPhotos",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "inspectionFindingPhotos",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "inspectionFindingPhotos",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "audits",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "audits",
                            "select": "id",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "audits",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "locationId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "audits",
                            "select": "locationId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "label",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "audits",
                            "select": "label",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "active",
                            "pk": 0,
                            "type": "boolean",
                            "table": "audits",
                            "select": "active",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "audits",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "audits",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "audits",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "audits",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "audits",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "audits",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                },
                {
                    "name": "auditItems",
                    "columns": [
                        {
                            "name": "id",
                            "pk": 1,
                            "type": "uuid",
                            "table": "auditItems",
                            "select": "id",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "companyId",
                            "pk": 2,
                            "type": "INTEGER",
                            "table": "auditItems",
                            "select": "companyId",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "auditId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "auditItems",
                            "select": "auditId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "procedureId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "auditItems",
                            "select": "procedureId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "revisedProcedureId",
                            "pk": 0,
                            "type": "uuid",
                            "table": "auditItems",
                            "select": "revisedProcedureId",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "status",
                            "pk": 0,
                            "type": "boolean",
                            "table": "auditItems",
                            "select": "status",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "notes",
                            "pk": 0,
                            "type": "TEXT",
                            "table": "auditItems",
                            "select": "notes",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "archived",
                            "pk": 0,
                            "type": "boolean",
                            "table": "auditItems",
                            "select": "archived",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "auditItems",
                            "select": "createdOn",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedOn",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "auditItems",
                            "select": "updatedOn",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "createdById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "auditItems",
                            "select": "createdById",
                            "insert": true,
                            "update": false
                        },
                        {
                            "name": "updatedById",
                            "pk": 0,
                            "type": "INTEGER",
                            "table": "auditItems",
                            "select": "updatedById",
                            "insert": true,
                            "update": true
                        },
                        {
                            "name": "lastActivity",
                            "pk": 0,
                            "type": "timestamp",
                            "table": "auditItems",
                            "select": "lastActivity",
                            "insert": false,
                            "update": false
                        }
                    ]
                }
            ]
        }
        else {
            const data = {
                tables: await (async () => {
                    const sqliteDb = db.withTables<SqliteTables>()
                    const tables = await sqliteDb.selectFrom("sqlite_master").select("name").where("type", "=", "table").execute()
                    const columns = await tables.map(table => {
                        return sqliteDb.selectFrom("pragma_table_info")
                            .select(["name", "pk", "type", "arg as table"])
                            .where("arg", "=", table.name)
                    }).reduce((a, b) => {
                        return a.unionAll(b)
                    }).execute()
                    return tables.map(table => {
                        return {
                            name: table.name,
                            columns: columns.filter(_ => _.table === table.name && Introspector.METAS.indexOf(_.name) === -1),
                        }
                    })
                })(),
                update: await this.apollo.query<Record<string, { enumValues: { name: string }[] }>>({
                    query: gql(jsonToGraphQLQuery({
                        query: Object.fromEntries(SYNC_TABLES.map(entity => {
                            const name = pascalCase(entity)
                            return [name, {
                                __aliasFor: "__type",
                                __args: {
                                    name: name + "UpdateColumn"
                                },
                                enumValues: {
                                    name: true
                                }
                            }]
                        }))
                    }))
                }),
                insert: await this.apollo.query<Record<string, { inputFields: { name: string, type: { inputFields?: { name: string }[] } }[] }>>({
                    query: gql(jsonToGraphQLQuery({
                        query: Object.fromEntries(SYNC_TABLES.map(entity => {
                            const name = pascalCase(entity)
                            return [name, {
                                __aliasFor: "__type",
                                __args: {
                                    name: name + "InsertInput",
                                },
                                inputFields: {
                                    name: true,
                                    type: {
                                        inputFields: {
                                            name: true
                                        }
                                    }
                                }
                            }]
                        }))
                    }))
                })
            }
            const introspection = SYNC_TABLES.map(name => {
                const dbName = name
                const graphQlName = pascalCase(name)
                const insertFields = data.insert.data[graphQlName]
                if (Ramda.isNil(insertFields)) {
                    throw new Error("Could not introspect an insert schema for " + graphQlName + ".")
                }
                const updateFields = data.update.data[graphQlName]
                if (Ramda.isNil(updateFields)) {
                    throw new Error("Could not introspect an update schema for " + graphQlName + ".")
                }
                const insert = insertFields.inputFields.filter(_ => !_.type.inputFields).map(_ => _.name)
                const update = updateFields.enumValues.map(_ => _.name)
                const columns = data.tables.filter(_ => _.name === dbName).flatMap(_ => _.columns).map(column => {
                    return {
                        ...column,
                        name: column.name,
                        select: column.name,
                        insert: insert.includes(column.name),
                        update: update.includes(column.name)
                    }
                })
                return { name, columns }
            })
            console.log("[Sync] Got introspection data from SQLite and GraphQL.", introspection)
            console.log(introspection)
            return introspection
        }
    }

}
