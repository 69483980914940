import { maybe } from "shared/maybe"
import { getMode } from "shared/misc"

// The application modes.

// The mode.

export const MODE = getMode(import.meta.env.MODE)

// Is the application in dev mode?

export const IS_DEV = ["development"].includes(MODE)
export const IS_STAGING = ["staging"].includes(MODE)
export const IS_PROD = ["production"].includes(MODE)

// Testing mode. Enables all kinds of weirdness like synthetic query delays etc.

export const IS_TESTING = IS_DEV

// Debug mode. When enabled, users will have the option to do things like export the DB, manually fill in QR code scanning popups, etc.

export const IS_DEBUG = !IS_PROD

// A synthetic delay when loading items. Used for testing.

export const SYNTHETIC_DELAY = IS_TESTING ? 200 : 0

// Base URLs and API keys.

export const BASE_URL = import.meta.env.BASE_URL
export const GRAPHQL_URL = maybe(import.meta.env.C4_GRAPHQL_URL)
export const UPLOADS_URL = maybe(import.meta.env.C4_UPLOADS_URL)
export const AG_GRID_LICENSE_KEY = maybe(import.meta.env.C4_AG_GRID_LICENSE_KEY)
export const SENTRY_DSN = maybe(import.meta.env.C4_SENTRY_DSN)

// Backend settings.

export const KYSELY_SAVE_CHUNK_SIZE = 128
export const KYSELY_RELOAD_CHUNK_SIZE = 1024
export const DEFAULT_QUERY_BREAKUP = 1024
export const MAX_VARIABLES_PER_QUERY = 32766
export const SQLITE_CACHE_SIZE = -1 * 32 * 1024
export const PRINT_CHUNK_SIZE = 2048

// How long to keep the Pica worker alive. Used in the file manager.

export const PICA_IDLE = 3600 * 1000

// How long to debounce before sending a notification about a database change.

export const DB_CHANGE_NOTIFICATION_DEBOUNCE = 10

// Synchronization and timing settings.

// The names of the locks and broadcast channels related to syncing.

export const SCHEDULER_LOCK = "scheduler"
export const SYNC_LOCK = "syncing"
export const MIGRATION_LOCK = "migration"
export const AUTH_TOKEN = "token-v1"

// The interval at which to sync.

export const SYNC_INTERVAL = 5 * 60 * 1000
export const SYNC_INTERVAL_AFTER_FAILURE = 1 * 60 * 1000

// LONG Use PG or don't (probably don't).

export const PG = false
export type PGWorker = any
export const BETA = IS_DEV
export type DBBoolean = 0 | 1
//export const DB_FALSE = false
//export const DB_TRUE = true
