
import AppWorker from "app/AppWorker"
import { IS_DEV, PGWorker } from 'config'
import { broadcastFinderBatching, wrapMigrating } from "observable-worker"
import { useCallback, useState } from 'react'
import { DexieAsyncStore } from "services/store/DexieAsyncStore"
import { useEffectOnce } from 'state-hooks'
import PrefixedStore from "ui/store/PrefixedStore"
import WindowStore from "ui/store/WindowStore"

const storageListeners = new Set<((key: string, value: string | undefined) => void)>()
storageListeners.add((key, value) => {
    if (value !== undefined) {
        window.localStorage.setItem(key, value)
    }
    else {
        window.localStorage.removeItem(key)
    }
})
window.addEventListener("storage", event => {
    storageListeners.forEach(listener => {
        if (event.key === null) {
            return
        }
        listener(event.key, event.newValue ?? undefined)
    })
})

export function useStorage(key: string) {
    const [value, updateValue] = useState(window.localStorage.getItem(key) ?? undefined)
    useEffectOnce(() => {
        storageListeners.add((eventKey, eventValue) => {
            if (eventKey === key) {
                updateValue(eventValue)
            }
        })
    })
    const setValue = useCallback((value: string | undefined) => {
        storageListeners.forEach(listener => listener(key, value))
    }, [
        key
    ])
    return [value, setValue] as const
}

/**
 * This class is used to manage global services and state.
 */
export default class Global {

    /**
     * The worker proxy.
     */
    private readonly remote

    /**
     * The worker proxy.
     */
    readonly proxy

    /**
     * A store for settings.
     */
    readonly store

    /**
     * A page store for settings.
     */
    readonly pageStore

    /**
     * Creates a new global object.
     */
    constructor(window: Window, readonly pg: PGWorker) {
        this.store = new PrefixedStore(new WindowStore(window.localStorage), IS_DEV ? "c4-collection-" : "")
        //TODO this seems to persist betwen tabs?
        this.pageStore = new WindowStore(window.sessionStorage)
        const work5er = new Worker(new URL("/src/app/PGWorker.ts", import.meta.url), { type: 'module' })
        const worker = new Worker(new URL("/src/app/AppWorker.ts", import.meta.url), { type: 'module' })
        //this. w = createPgLiteWorker()
        this.remote = wrapMigrating<AppWorker>({ finder: broadcastFinderBatching() })
        this.proxy = this.remote.proxy
    }

    static async create(window: Window) {
        return new Global(window, await createPgLiteWorker())
    }

    /**
     * Closes the global object.
     */
    close() {
    }

    fileStore() {

    }

    //TODO remove all user/company stores - just specify your own keys
    //TODO rm
    userFileStore(id: number) {
        return DexieAsyncStore.selfManaging<readonly [number, Uint8Array], Blob | undefined>("blobs-" + id)
    }

}

function createPgLiteWorker() {
    return
    /*
    return PGliteWorker.create(new Worker(new URL("/src/app/PGWorker.ts", import.meta.url), {
        type: "module"
    }), {
        extensions: {
            live
        }
    })
        */
}