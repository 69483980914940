import { useBoolean } from './boolean.mjs';
import { useEventOnce } from './event.mjs';

/**
 * Tracks the online status of the browser.
 * @returns Whether the browser is online.
 */
function useOnline() {
    const online = useBoolean(window.navigator.onLine);
    useEventOnce(window, "online", online.on);
    useEventOnce(window, "offline", online.off);
    return online.value;
}

export { useOnline };
