import { equals } from 'ramda';
import { useState, useMemo } from 'react';
import { useDeepCompareConstant } from './util.mjs';

/**
 * Build the initial form state.
 * @param initialValue The form's value.
 * @returns An initial form state.
 */
function initialFormState(initialValue) {
    return {
        lastInitialized: new Date(),
        initializedValue: initialValue,
        value: initialValue,
        //submitCount: 0,
        //validateRequests: 0,
        //  isSubmitting: false,
        // isValidating: false,
    };
}
function useFormState(options) {
    const [state, setState] = useState(initialFormState(options.initialValue));
    //TODO this wont be accurate - we need a counter or something
    // const isValidating = state.isValidating// > 0
    const isValidating = (state.lastValidateRequested?.getTime() ?? 0) > (state.lastValidated?.getTime() ?? 0);
    const isSubmitting = (state.lastSubmitRequested?.getTime() ?? 0) > (state.lastSubmitted?.getTime() ?? 0);
    // const isValidationCurrent = (state.lastValidated?.getTime() ?? 0) > (state.lastChanged?.getTime() ?? 0)
    // const isInvalid = !isValidationCurrent || state.errors === undefined ? undefined : state.errors.length !== 0
    // const isValid = !isValidationCurrent || state.errors === undefined ? undefined : state.errors.length === 0
    const canSubmit = (() => {
        return !(state.errors ?? []).some(_ => _.temporary !== true);
        /*
        return valid
        if (valid) {
            return true
            //return !isValidating
        }
        else {
            return false
        }
        */
    })();
    const isDirty = useMemo(() => (state.lastChanged?.getTime() ?? 0) > state.lastInitialized.getTime() && !equals(state.value, state.initializedValue), [state.value, state.initializedValue]);
    const isDirtySinceSubmitted = useMemo(() => (state.lastChanged?.getTime() ?? 0) > (state.lastSubmitted?.getTime() ?? 0) && !equals(state.value, state.submittedValue ?? state.initializedValue), [state.value, state.submittedValue ?? state.initializedValue]);
    const isFocused = (state.lastFocused?.getTime() ?? 0) > (state.lastBlurred?.getTime() ?? 0);
    //TODO rename to clarify dif between initial vs initilized value?
    const initialValue = useDeepCompareConstant(options.initialValue);
    //TODO do we need sinceSubmitted, sinceValidated, etc
    const value = {
        ...state,
        isValidating,
        isSubmitting,
        canSubmit,
        //  isValid,
        //  isInvalid,
        initialValue,
        isDirty,
        isDirtySinceSubmitted,
        isFocused,
    };
    return [value, setState];
}

export { initialFormState, useFormState };
