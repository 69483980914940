import { Compilable, CompiledQuery, ConnectionProvider, DefaultConnectionProvider, DefaultQueryExecutor, Dialect, DialectAdapter, Driver, Kysely, KyselyConfig, KyselyPlugin, KyselyProps, QueryCompiler, QueryCreator, QueryExecutor, QueryResult, UnknownRow, isKyselyProps } from "kysely";
import { RxJS } from "namespaces/RxJS";
import { nanoid } from "nanoid";
import { Observable } from "rxjs";
import { maybe } from "shared/maybe";

type DrainOuterGeneric<T> = [T] extends [unknown] ? T : never

export interface ObservableDialect extends Dialect {

    createDriver(): ObservableDriver

}

export interface ObservableDriver extends Driver {

    observeQuery<R>(compiledQuery: CompiledQuery<R>): Observable<QueryResult<R>>

}

export interface ObservableKyselyConfig extends KyselyConfig {

    readonly dialect: ObservableDialect

}

export interface ObservableKyselyProps extends KyselyProps {

    readonly driver: ObservableDriver
    readonly executor: ObservableQueryExecutor

}

export interface ObservableQueryExecutor extends QueryExecutor {

    observeQuery<R extends UnknownRow>(compiledQuery: CompiledQuery<R>): Observable<QueryResult<R>>

    withPlugin(plugin: KyselyPlugin): ObservableQueryExecutor
    withPlugins(plugin: ReadonlyArray<KyselyPlugin>): ObservableQueryExecutor
    withPluginAtFront(plugin: KyselyPlugin): ObservableQueryExecutor
    withoutPlugins(): ObservableQueryExecutor

}

export class DefaultObservableQueryExector extends DefaultQueryExecutor implements ObservableQueryExecutor {

    readonly #driver: ObservableDriver
    readonly #compiler: QueryCompiler
    readonly #adapter: DialectAdapter
    readonly #connectionProvider: ConnectionProvider

    constructor(driver: ObservableDriver, compiler: QueryCompiler, adapter: DialectAdapter, connectionProvider: ConnectionProvider, plugins: KyselyPlugin[] = []) {
        super(compiler, adapter, connectionProvider, plugins)
        this.#driver = driver
        this.#compiler = compiler
        this.#adapter = adapter
        this.#connectionProvider = connectionProvider
    }

    observeQuery<R extends UnknownRow>(compiledQuery: CompiledQuery<R>) {
        if (compiledQuery.query.kind !== "SelectQueryNode") {
            throw new Error("You can only observe select queries.")
        }
        const id = { queryId: nanoid() } // use randomString from kysely (doesnt seem to be exported)
        const observable = this.#driver.observeQuery(compiledQuery)
        return observable.pipe(
            RxJS.mergeMap(async result => {
                return await this.transformResults<R>(id, result)
            })
        )
    }

    // not necessary - use the one on executor - only made this because that one is private
    protected async transformResults<O extends UnknownRow>(queryId: { readonly queryId: string }, result: QueryResult<O>) {
        const transformed = await (this.plugins ?? []).reduce<PromiseLike<QueryResult<O>>>(
            async (prev, current) => {
                return current.transformResult({
                    queryId,
                    result: await prev
                }) as PromiseLike<QueryResult<O>>
            },
            Promise.resolve(result)
        )
        return transformed
    }

    override withPlugins(plugins: ReadonlyArray<KyselyPlugin>) {
        return new DefaultObservableQueryExector(this.#driver, this.#compiler, this.#adapter, this.#connectionProvider, [...this.plugins, ...plugins])
    }
    override withPlugin(plugin: KyselyPlugin) {
        return new DefaultObservableQueryExector(this.#driver, this.#compiler, this.#adapter, this.#connectionProvider, [...this.plugins, plugin])
    }
    override withPluginAtFront(plugin: KyselyPlugin) {
        return new DefaultObservableQueryExector(this.#driver, this.#compiler, this.#adapter, this.#connectionProvider, [plugin, ...this.plugins])
    }
    override withConnectionProvider(connectionProvider: ConnectionProvider) {
        return new DefaultObservableQueryExector(this.#driver, this.#compiler, this.#adapter, connectionProvider, [...this.plugins])
    }
    override withoutPlugins() {
        return new DefaultObservableQueryExector(this.#driver, this.#compiler, this.#adapter, this.#connectionProvider)
    }

}

export interface ObservableQueryCreator<D> extends QueryCreator<D> {

    observe<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>): Observable<O[]>
    observeTakeFirst<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>): Observable<O | undefined>
    observeTakeFirstOrThrow<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>): Observable<O>

}

export class ObservableKysely<D> extends Kysely<D> implements ObservableQueryCreator<D> {

    readonly #props

    constructor(args: ObservableKyselyConfig)
    constructor(args: ObservableKyselyProps)
    constructor(args: ObservableKyselyProps | ObservableKyselyConfig) {
        let props: ObservableKyselyProps
        if (isKyselyProps(args)) {
            props = args
        }
        else {
            const dialect = args.dialect
            const driver = dialect.createDriver()
            const compiler = dialect.createQueryCompiler()
            const adapter = dialect.createAdapter()
            const connectionProvider = new DefaultConnectionProvider(driver)
            const executor = new DefaultObservableQueryExector(driver, compiler, adapter, connectionProvider, args.plugins)
            props = {
                config: args,
                executor,
                dialect,
                driver,
            }
        }
        super(props)
        this.#props = props
    }

    /*
    executable<O extends UnknownRow>(compiled: CompiledQuery<O>) {
        return {
            observe: () => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows))
            },
            observeTakeFirst: () => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows.at(0)))
            },
            observeTakeFirstOrThrow: () => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => maybe(value, "This query has no results.")))
            },
            observeField: <K extends keyof O>(key: K) => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows), RxJS.map(rows => rows.map(row => row[key])))
            },
            observeFieldTakeFirst: <K extends keyof O>(key: K) => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows.at(0)), RxJS.map(value => value === undefined ? undefined : value[key]))
            },
            observeFieldTakeFirstOrThrow: <K extends keyof O>(key: K) => {
                return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows.at(0)), RxJS.map(value => maybe(value, "This query has no results.")), RxJS.map(value => value === undefined ? undefined : value[key]))
            },
        }
    }*/

    observe<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observeCompiled(factory(this).compile())
    }
    observeCompiled<O extends UnknownRow>(compiled: CompiledQuery<O>) {
        return this.#props.executor.observeQuery(compiled).pipe(RxJS.map(value => value.rows))
    }
    observeField<O extends UnknownRow, K extends keyof O>(key: K, factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observeCompiledField(key, factory(this).compile())
    }
    observeCompiledField<O extends UnknownRow, K extends keyof O>(key: K, compiled: CompiledQuery<O>) {
        return this.observeCompiled(compiled).pipe(RxJS.map(rows => rows.map(row => row[key])))
    }
    observeTakeFirst<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observe(factory).pipe(RxJS.map(value => value.at(0)))
    }
    observeCompiledTakeFirst<O extends UnknownRow>(compiled: CompiledQuery<O>) {
        return this.observeCompiled(compiled).pipe(RxJS.map(value => value.at(0)))
    }
    observeFieldTakeFirst<O extends UnknownRow, K extends keyof O>(key: K, factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observeTakeFirst(factory).pipe(RxJS.map(value => value === undefined ? undefined : value[key]))
    }
    observeTakeFirstOrThrow<O extends UnknownRow>(factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observeCompiledTakeFirstOrThrow(factory(this).compile())
        //.pipe(RxJS.map(value => maybe(value, "This query has no results.")))
    }
    observeCompiledTakeFirstOrThrow<O extends UnknownRow>(compiled: CompiledQuery<O>) {
        return this.observeCompiledTakeFirst(compiled).pipe(RxJS.map(value => maybe(value, "This query has no results.")))
    }
    observeFieldTakeFirstOrThrow<O extends UnknownRow, K extends keyof O>(key: K, factory: (qc: QueryCreator<D>) => Compilable<O>) {
        return this.observeTakeFirstOrThrow(factory).pipe(RxJS.map(row => row[key]))
    }

    override withTables<T extends Record<string, Record<string, any>>>(): ObservableKysely<DrainOuterGeneric<D & T>> & Kysely<DrainOuterGeneric<D & T>> {
        return new ObservableKysely(this.#props)
    }
    override withPlugin(plugin: KyselyPlugin) {
        return new ObservableKysely<D>({ ...this.#props, executor: this.#props.executor.withPlugin(plugin) })
    }
    override withoutPlugins() {
        return new ObservableKysely<D>({ ...this.#props, executor: this.#props.executor.withoutPlugins() })
    }

}
