import { pipe } from 'fp-ts/function';
import { of } from './core.mjs';
import { throwError, groupErrors, groupMessages, combineMessages, messageList, singleError, valueOnly, errorsOnly, errorMessagesOnly } from './errors.mjs';

/**
 * Executes a mapper on an input value, returning a result.
 */
const exec = (value, mapper) => pipe(value, of, mapper);
/**
 * Throws if there is an error, otherwise returns the value.
 */
const execOrThrow = (value, mapper, formatter) => pipe(value, of, mapper, throwError(formatter));
const execOrGroup = (value, mapper) => pipe(value, of, mapper, groupErrors());
const execOrMessages = (value, mapper, formatter) => pipe(value, of, mapper, groupMessages(formatter));
const execOrCombinedMessages = (value, mapper, formatter) => pipe(value, of, mapper, combineMessages(formatter));
const execOrMessageList = (value, mapper, formatter) => pipe(value, of, mapper, messageList(formatter));
/**
 * Turns the errors into one string.
 */
const execOrSingleError = (value, mapper, formatter) => pipe(value, of, mapper, singleError(formatter));
/**
 * Returns only successful values. If there were errors, the value is undefined.
 */
const execValueOnly = (value, mapper) => pipe(value, of, mapper, valueOnly());
const execErrorsOnly = (value, mapper) => pipe(value, of, mapper, errorsOnly());
const execErrorMessagesOnly = (value, mapper, formatter) => pipe(value, of, mapper, errorMessagesOnly(formatter));

export { exec, execErrorMessagesOnly, execErrorsOnly, execOrCombinedMessages, execOrGroup, execOrMessageList, execOrMessages, execOrSingleError, execOrThrow, execValueOnly };
