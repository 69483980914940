import { equals } from 'ramda';

/**
 * Turn a form error input into an array of form errors.
 * @param input The input.
 * @returns Form errors.
 */
function buildErrors(input) {
    if (Array.isArray(input)) {
        return input.flatMap(buildErrors);
    }
    else if (typeof input === "string") {
        return [{
                path: [],
                message: input,
            }];
    }
    else if (input === undefined) {
        return [];
    }
    else {
        return [input];
    }
}
function stringifyErrors(errors) {
    if (errors === undefined) {
        return;
    }
    if (errors.length === 0) {
        return;
    }
    return errors.map(_ => _.message).join(", ") + ".";
}
function errorAt(path) {
    return (error) => {
        return equals(path, error.path);
    };
}
function descendErrors(errors, path) {
    return errors.filter(_ => equals((_.path ?? []).slice(0, path.length), path ?? [])).map(error => ({ ...error, path: (error.path ?? []).slice(path.length) }));
}

export { buildErrors, descendErrors, errorAt, stringifyErrors };
