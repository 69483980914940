import AppWorker from "app/AppWorker"
import { PG } from "config"
import { saveAs } from "file-saver"
import { Proxied } from "observable-worker"
import Global from "services/Global"
import Store from "ui/store/Store"

export default class DataManager {

    constructor(private readonly store: Store, private readonly global: Global, private readonly worker: Proxied<AppWorker>, private readonly logOut: () => void, private readonly id: number) {
    }

    async import(data: Uint8Array | ArrayBuffer) {
        await this.worker.load(await this.worker.open(this.id.toString()), data)
    }
    async export() {
        //const localStorage = new Blob([JSON.stringify({ ...window.localStorage }, null, "\t")], { type: "application/json" })
        const db = new Blob([await this.worker.export(await this.worker.open(this.id.toString()))], { type: "application/octet-stream" })
        //saveAs(localStorage, "LocalStorage.json")
        saveAs(db, "DB.sqlite")
    }
    async clear() {
        //TODO do
        await this.worker.delete(await this.worker.open(this.id.toString()))
        this.store.clear()
        this.logOut()
    }
    async migrate() {
        console.log("[Services] Starting migration...")
        if (PG) {
            //await migratePg(this.global.pg)
        }
        else {
            const connection = await this.worker.open(this.id.toString())
            try {
                await this.worker.migrate(connection)
            }
            finally {
                await this.worker.close(connection)
            }
        }
    }

}
