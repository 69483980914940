
/**
 * - Some way to notify when the app has changed, and prompt for a reload?
 * - Multiple filters in table.
 * - no easy way to edit the Hazards column on procedures page on a touch screen?
 * 
 * shrink camera on ipad or landscape
 * 
 * PHOTO FIELD IN AUDIT TOOL
 * MULTIPLE PHOTOS - NOTES PER PHOTO
 * 
 * test out delivery scan on a phone - ui is kinda sloppy looking
 * 
 * find a way to not have to do pdf chunks
 * procedure page - not all menu items disable whn you hit reload
 * add phone num to users
find and delete all relative links
/**
 * on bulk upload page - disable camera button while uploading
 * 
 * template edit text box is very short


Jul 17, 7:33 PM
Terms and conditions
 * mark everyone as accepted tos except for jason and anthony from ers

why the fuck is the procedure dropdown when creating an inspection item not async? its slow as shit
https://app.thelotogroup.com/company/16/audit/cBS9mNBoRJSrR44H83EEPw/create

add id columns for control points on ALL tables - not just full procedures table?

move shv1 to ERS

make file import tolerant of duplicates

some kind of full export/import of everything

printing for audits
disable scan asset tab if no camera on audits

camera resolution on surface pro is poor
templates from dky8

*/

import { DBBoolean } from "config"
import { Ramda } from "namespaces/Ramda"

/**
 * Convenience types.
 */
export const DB_TRUE: DBBoolean = 1
export const DB_FALSE: DBBoolean = 0

/**
 * A bootstrap break.
 */
export type Break = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

/**
 * Require only the specified keys.
 */
export type RequireOnly<T, K extends keyof T> = Partial<{ [X in keyof Omit<T, K>]: T[X] | undefined | null }> & Pick<T, K>

/**
 * A utility function to create a query string.
 * @param object An object of the query parameters.
 * @returns A query string.
 */
export function queryString(object: Record<string, string | undefined>) {
    return new URLSearchParams(Ramda.pickBy(Ramda.isNotNil, object)).toString()
}
