export default {
    "scalars": [
        0,
        3,
        4,
        5,
        7,
        8,
        10,
        15,
        31,
        43
    ],
    "types": {
        "Boolean": {},
        "BooleanComparisonExp": {
            "_eq": [
                0
            ],
            "_gt": [
                0
            ],
            "_gte": [
                0
            ],
            "_in": [
                0
            ],
            "_isNull": [
                0
            ],
            "_lt": [
                0
            ],
            "_lte": [
                0
            ],
            "_neq": [
                0
            ],
            "_nin": [
                0
            ],
            "__typename": [
                10
            ]
        },
        "BulkPrintOutput": {
            "urls": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "CursorOrdering": {},
        "Float": {},
        "Int": {},
        "IntComparisonExp": {
            "_eq": [
                5
            ],
            "_gt": [
                5
            ],
            "_gte": [
                5
            ],
            "_in": [
                5
            ],
            "_isNull": [
                0
            ],
            "_lt": [
                5
            ],
            "_lte": [
                5
            ],
            "_neq": [
                5
            ],
            "_nin": [
                5
            ],
            "__typename": [
                10
            ]
        },
        "OrderBy": {},
        "Paging": {},
        "PrintOutput": {
            "url": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "String": {},
        "StringComparisonExp": {
            "_eq": [
                10
            ],
            "_gt": [
                10
            ],
            "_gte": [
                10
            ],
            "_ilike": [
                10
            ],
            "_in": [
                10
            ],
            "_iregex": [
                10
            ],
            "_isNull": [
                0
            ],
            "_like": [
                10
            ],
            "_lt": [
                10
            ],
            "_lte": [
                10
            ],
            "_neq": [
                10
            ],
            "_nilike": [
                10
            ],
            "_nin": [
                10
            ],
            "_niregex": [
                10
            ],
            "_nlike": [
                10
            ],
            "_nregex": [
                10
            ],
            "_nsimilar": [
                10
            ],
            "_regex": [
                10
            ],
            "_similar": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "Time": {
            "now": [
                43
            ],
            "__typename": [
                10
            ]
        },
        "TimeBoolExp": {
            "_and": [
                13
            ],
            "_not": [
                13
            ],
            "_or": [
                13
            ],
            "now": [
                18
            ],
            "__typename": [
                10
            ]
        },
        "TimeOrderBy": {
            "now": [
                7
            ],
            "__typename": [
                10
            ]
        },
        "TimeSelectColumn": {},
        "TimeStreamCursorInput": {
            "initialValue": [
                17
            ],
            "ordering": [
                3
            ],
            "__typename": [
                10
            ]
        },
        "TimeStreamCursorValueInput": {
            "now": [
                43
            ],
            "__typename": [
                10
            ]
        },
        "TimestamptzComparisonExp": {
            "_eq": [
                43
            ],
            "_gt": [
                43
            ],
            "_gte": [
                43
            ],
            "_in": [
                43
            ],
            "_isNull": [
                0
            ],
            "_lt": [
                43
            ],
            "_lte": [
                43
            ],
            "_neq": [
                43
            ],
            "_nin": [
                43
            ],
            "__typename": [
                10
            ]
        },
        "TokenData": {
            "expires": [
                5
            ],
            "token": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "UploadTemporaryOutput": {
            "downloadUrl": [
                10
            ],
            "key": [
                10
            ],
            "uploadUrl": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "Users": {
            "acceptedTos": [
                0
            ],
            "admin": [
                0
            ],
            "archived": [
                0
            ],
            "createdOn": [
                43
            ],
            "created_by": [
                21
            ],
            "developer": [
                0
            ],
            "emailAddress": [
                10
            ],
            "id": [
                5
            ],
            "name": [
                10
            ],
            "password": [
                10
            ],
            "updated_by": [
                21
            ],
            "__typename": [
                10
            ]
        },
        "UsersAggregate": {
            "aggregate": [
                23
            ],
            "nodes": [
                21
            ],
            "__typename": [
                10
            ]
        },
        "UsersAggregateFields": {
            "avg": [
                24
            ],
            "count": [
                5,
                {
                    "columns": [
                        31,
                        "[UsersSelectColumn!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                26
            ],
            "min": [
                27
            ],
            "stddev": [
                33
            ],
            "stddevPop": [
                34
            ],
            "stddevSamp": [
                35
            ],
            "sum": [
                38
            ],
            "varPop": [
                40
            ],
            "varSamp": [
                41
            ],
            "variance": [
                42
            ],
            "__typename": [
                10
            ]
        },
        "UsersAvgFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersBoolExp": {
            "_and": [
                25
            ],
            "_not": [
                25
            ],
            "_or": [
                25
            ],
            "acceptedTos": [
                1
            ],
            "admin": [
                1
            ],
            "archived": [
                1
            ],
            "createdOn": [
                18
            ],
            "created_by": [
                25
            ],
            "developer": [
                1
            ],
            "emailAddress": [
                11
            ],
            "id": [
                6
            ],
            "name": [
                11
            ],
            "password": [
                11
            ],
            "updated_by": [
                25
            ],
            "__typename": [
                10
            ]
        },
        "UsersMaxFields": {
            "createdOn": [
                43
            ],
            "emailAddress": [
                10
            ],
            "id": [
                5
            ],
            "name": [
                10
            ],
            "password": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "UsersMinFields": {
            "createdOn": [
                43
            ],
            "emailAddress": [
                10
            ],
            "id": [
                5
            ],
            "name": [
                10
            ],
            "password": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "UsersMutationResponse": {
            "affectedRows": [
                5
            ],
            "returning": [
                21
            ],
            "__typename": [
                10
            ]
        },
        "UsersOrderBy": {
            "acceptedTos": [
                7
            ],
            "admin": [
                7
            ],
            "archived": [
                7
            ],
            "createdOn": [
                7
            ],
            "created_by": [
                29
            ],
            "developer": [
                7
            ],
            "emailAddress": [
                7
            ],
            "id": [
                7
            ],
            "name": [
                7
            ],
            "password": [
                7
            ],
            "updated_by": [
                29
            ],
            "__typename": [
                10
            ]
        },
        "UsersPkColumnsInput": {
            "id": [
                5
            ],
            "__typename": [
                10
            ]
        },
        "UsersSelectColumn": {},
        "UsersSetInput": {
            "acceptedTos": [
                0
            ],
            "emailAddress": [
                10
            ],
            "name": [
                10
            ],
            "password": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "UsersStddevFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersStddevPopFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersStddevSampFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersStreamCursorInput": {
            "initialValue": [
                37
            ],
            "ordering": [
                3
            ],
            "__typename": [
                10
            ]
        },
        "UsersStreamCursorValueInput": {
            "acceptedTos": [
                0
            ],
            "admin": [
                0
            ],
            "archived": [
                0
            ],
            "createdOn": [
                43
            ],
            "developer": [
                0
            ],
            "emailAddress": [
                10
            ],
            "id": [
                5
            ],
            "name": [
                10
            ],
            "password": [
                10
            ],
            "__typename": [
                10
            ]
        },
        "UsersSumFields": {
            "id": [
                5
            ],
            "__typename": [
                10
            ]
        },
        "UsersUpdates": {
            "_set": [
                32
            ],
            "where": [
                25
            ],
            "__typename": [
                10
            ]
        },
        "UsersVarPopFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersVarSampFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "UsersVarianceFields": {
            "id": [
                4
            ],
            "__typename": [
                10
            ]
        },
        "timestamptz": {},
        "Query": {
            "time": [
                12,
                {
                    "distinctOn": [
                        15,
                        "[TimeSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        14,
                        "[TimeOrderBy!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "uploadTemporary": [
                20,
                {
                    "name": [
                        10,
                        "String!"
                    ]
                }
            ],
            "users": [
                21,
                {
                    "distinctOn": [
                        31,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        29,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        25
                    ]
                }
            ],
            "usersAggregate": [
                22,
                {
                    "distinctOn": [
                        31,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        29,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        25
                    ]
                }
            ],
            "usersByPk": [
                21,
                {
                    "id": [
                        5,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                10
            ]
        },
        "Mutation": {
            "bulkPrint": [
                2,
                {
                    "height": [
                        4,
                        "Float!"
                    ],
                    "output": [
                        10,
                        "String!"
                    ],
                    "paging": [
                        8,
                        "Paging!"
                    ],
                    "url": [
                        10,
                        "String!"
                    ],
                    "width": [
                        4,
                        "Float!"
                    ]
                }
            ],
            "print": [
                9,
                {
                    "height": [
                        4,
                        "Float!"
                    ],
                    "html": [
                        10,
                        "String!"
                    ],
                    "name": [
                        10,
                        "String!"
                    ],
                    "paging": [
                        8,
                        "Paging!"
                    ],
                    "width": [
                        4,
                        "Float!"
                    ]
                }
            ],
            "token": [
                19
            ],
            "updateUsers": [
                28,
                {
                    "_set": [
                        32
                    ],
                    "where": [
                        25,
                        "UsersBoolExp!"
                    ]
                }
            ],
            "updateUsersByPk": [
                21,
                {
                    "_set": [
                        32
                    ],
                    "pkColumns": [
                        30,
                        "UsersPkColumnsInput!"
                    ]
                }
            ],
            "updateUsersMany": [
                28,
                {
                    "updates": [
                        39,
                        "[UsersUpdates!]!"
                    ]
                }
            ],
            "__typename": [
                10
            ]
        },
        "Subscription": {
            "time": [
                12,
                {
                    "distinctOn": [
                        15,
                        "[TimeSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        14,
                        "[TimeOrderBy!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "timeStream": [
                12,
                {
                    "batchSize": [
                        5,
                        "Int!"
                    ],
                    "cursor": [
                        16,
                        "[TimeStreamCursorInput]!"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "users": [
                21,
                {
                    "distinctOn": [
                        31,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        29,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        25
                    ]
                }
            ],
            "usersAggregate": [
                22,
                {
                    "distinctOn": [
                        31,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        5
                    ],
                    "offset": [
                        5
                    ],
                    "orderBy": [
                        29,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        25
                    ]
                }
            ],
            "usersByPk": [
                21,
                {
                    "id": [
                        5,
                        "Int!"
                    ]
                }
            ],
            "usersStream": [
                21,
                {
                    "batchSize": [
                        5,
                        "Int!"
                    ],
                    "cursor": [
                        36,
                        "[UsersStreamCursorInput]!"
                    ],
                    "where": [
                        25
                    ]
                }
            ],
            "__typename": [
                10
            ]
        }
    }
};
