import { arrayOrElement } from './internal.mjs';

/**
 * Utility to format an error path.
 */
function formatPath(path) {
    if (path === undefined) {
        return "";
    }
    return arrayOrElement(path).map((value, index) => typeof value === "string" ? ((index > 0) ? "." : "") + value : "[" + value + "]").join("");
}
/**
 * Prints an error into a message string. Replaces {value} with value. Also appends the value to the end if includeValues is true.
 */
function formatError(includeValues = false) {
    return (errors) => {
        return arrayOrElement(errors)
            .map(_ => _.message.replace("{value}", `${_.value}`) + (includeValues ? " (" + `${_.value}` + ")" : ""))
            .join(", ");
    };
}
/**
 * Constructs a prefix sentence referencing an error path.
 */
function errorAt(path, message) {
    if (path === "") {
        return message;
    }
    return "Error(s) at " + path + ": " + message;
}

export { errorAt, formatError, formatPath };
