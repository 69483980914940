import { flow } from 'fp-ts/function';
import { flattenArray } from './flatten.mjs';
import { map } from './core.mjs';
import 'value-or-factory';
import { exec } from './exec.mjs';
import 'fp-ts/Either';
import 'js-base64';
import 'fp-ts/Array';
import 'ramda';
import 'fp-ts/lib/function';
import 'fp-ts/lib/Array';
import './specialty.mjs';
import 'uuid';

/**
 * Converts an array or element to an array.
 */
function arrayOrElement(value) {
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}
/**
 * Applies a mapper to each element of an array. This does NOT append anything to the error path. For internal use only. Used for multiple traversals.
 */
function loop(mapper) {
    return flow(map((input) => input.map((value, index) => exec(value, mapper(index)))), flattenArray());
}

export { arrayOrElement, loop };
