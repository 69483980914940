//TODO Move?
function execAction(form, action) {
    const actions = [action].flat();
    actions.forEach(action => {
        if (typeof action === "string") {
            form[action]();
        }
        else {
            action?.();
        }
    });
    /*
    if (typeof action === "string") {
        form[action]()
    }
    else {
        action?.(form)
    }
    */
}

export { execAction };
