import { PHOTO_SIZES } from "./config.js";
import { maybe } from "./maybe.js";
import { Ramda } from "./namespaces/Ramda.js";
/**
 * Gets an upload's original URL.
 */
export function uploadUrl(rootUrl, companyId, id, size) {
    return rootUrl + "/" + companyId + "/" + id + maybe(size, size => "@" + size.join("x"), () => "");
}
/**
 * Get an upload's online URLs including thumbnails. Note that this does not check the local database.
 */
export function uploadAndThumbnailUrls(rootUrl, companyId, id, info) {
    const width = info.width;
    const height = info.height;
    if (info.resized && !Ramda.isNil(width) && !Ramda.isNil(height)) {
        return {
            url: uploadUrl(rootUrl, companyId, id),
            thumbnails: PHOTO_SIZES.filter(size => size[0] <= width && size[1] <= height).map(size => {
                return {
                    size,
                    url: uploadUrl(rootUrl, companyId, id, size)
                };
            })
        };
    }
    else if (info.present) {
        return {
            url: uploadUrl(rootUrl, companyId, id)
        };
    }
}
