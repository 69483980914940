import { flow } from 'fp-ts/function';
import { array } from './array.mjs';
import { buildError } from './base.mjs';
import { map, flatMap, of, failure } from './core.mjs';
import { maybe, maybeOr, maybeOrUndefined, maybeOrNull } from './optionality.mjs';
import { predicate } from './test.mjs';

const typed = (value) => value;
const typedMaybe = (value) => maybe((typed))(value);
const typedOr = (factory) => maybeOr((typed), factory);
const typedOrUndefined = (value) => maybeOrUndefined((typed))(value);
const typedOrNull = (value) => maybeOrNull((typed))(value);
/**
 * Casts an unknown value to a type.
 */
const cast = (value) => map(_ => _)(value);
/**
 * A no-op string typed mapper, used for type inference.
 */
const stringType = (typed);
/**
 * A no-op boolean typed mapper, used for type inference.
 */
const booleanType = (typed);
/**
 * A no-op number typed mapper, used for type inference.
 */
const numberType = (typed);
/**
 * A no-op bigint typed mapper, used for type inference.
 */
const bigintType = (typed);
/**
 * A no-op object typed mapper, used for type inference.
 */
const objectType = (typed);
/**
 * A no-op symbol typed mapper, used for type inference.
 */
const symbolType = (typed);
/**
 * A no-op Date typed mapper, used for type inference.
 */
const dateType = (typed);
/**
 * A no-op ArrayBuffer typed mapper, used for type inference.
 */
const arrayBufferType = (typed);
/**
 * A no-op ArrayBufferView typed mapper, used for type inference.
 */
const arrayBufferViewType = (typed);
const isNull = (message) => predicate(_ => _ === null, message);
const isUndefined = (message) => predicate(_ => _ === undefined, message);
const isInstanceOf = (con, message = "This value must be an instance of " + con.name) => flatMap((_) => _ instanceof con ? of(_) : failure(buildError(message, _)));
const isType = (type, message = _ => "This value must be of type " + type) => flow(predicate(_ => typeof _ === type, message), (cast));
const isString = (message) => isType("string", message);
const isStringArray = (message) => flow(isArray(message), array(isString(message)));
const isBoolean = (message) => isType("boolean", message);
const isBooleanArray = (message) => flow(isArray(message), array(isBoolean(message)));
const isNumber = (message) => isType("number", message);
const isNumberArray = (message) => flow(isArray(message), array(isNumber(message)));
const isBigint = (message) => isType("bigint", message);
const isBigintArray = (message) => flow(isArray(message), array(isBigint(message)));
const isObject = (message) => isType("object", message);
const isObjectArray = (message) => flow(isArray(message), array(isObject(message)));
const isSymbol = (message) => isType("symbol", message);
const isSymbolArray = (message) => flow(isArray(message), array(isSymbol(message)));
const isArray = (message = "This value must be an array") => flow(predicate(Array.isArray, message), (cast));
const isArrayArray = (message) => flow(isArray(message), array(isArray(message)));
const isArrayOf = (type, message = _ => "This value must be of type " + type) => flow(isArray(message), array(isType(type, message)));
const isDate = (message = "This value must be a date") => flatMap((_) => _ instanceof Date ? of(_) : failure(buildError(message, _)));
const isDateArray = (message) => flow(isArray(message), array(isDate(message)));
const isArrayBufferView = (message = "This value must be an array buffer view") => flatMap((_) => ArrayBuffer.isView(_) ? of(_) : failure(buildError(message, _)));
const isArrayBufferViewArray = (message) => flow(isArray(message), array(isArrayBufferView(message)));

export { arrayBufferType, arrayBufferViewType, bigintType, booleanType, cast, dateType, isArray, isArrayArray, isArrayBufferView, isArrayBufferViewArray, isArrayOf, isBigint, isBigintArray, isBoolean, isBooleanArray, isDate, isDateArray, isInstanceOf, isNull, isNumber, isNumberArray, isObject, isObjectArray, isString, isStringArray, isSymbol, isSymbolArray, isType, isUndefined, numberType, objectType, stringType, symbolType, typed, typedMaybe, typedOr, typedOrNull, typedOrUndefined };
