
import { LicenseManager } from "@ag-grid-enterprise/core"
import { init } from "@sentry/browser"
import * as Sentry from "@sentry/react"
import App from "app/App"
import { AG_GRID_LICENSE_KEY, IS_PROD, SENTRY_DSN } from "config"
import { renderRoot } from "ui/index"

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

/*
if (import.meta.env.DEV) {
    if (import.meta.hot) {
        import.meta.hot.accept()
    }
}
*/

if (IS_PROD) {
    init({
        dsn: SENTRY_DSN,
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "AG Grid: Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects or enable convertValuesToStrings?",
        ],
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

renderRoot(<App />)

/*
beforeSend(event) {
    if (event.message === undefined) {
        return null
    }
    const ignore = [
        "ResizeObserver loop limit exceeded",
        "AG Grid: Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects or enable convertValuesToStrings?",
    ]
    if (ignore.includes(event.message)) {
        return null
    }
    return event
}*/