

import { DB_TRUE } from "adapters";
import Procedure from "domain/entities/Procedure";
import { Expression, ExpressionBuilder, expressionBuilder, Insertable, QueryCreator, sql } from "kysely";
import { Nil } from "shared/misc";
import { SELECT_LIMIT } from "ui/config";
//import { QueryCreatorWithCommonTableExpression } from "kysely"
import { DB_FALSE } from "adapters";
import { ID, newId } from "adapters/ids";
import Asset from "domain/entities/Asset";
import AuditFinding from "domain/entities/AuditFinding";
import ControlPoint from "domain/entities/ControlPoint";
import { TemplateType } from "domain/entities/Template";
import { Ramda } from "namespaces/Ramda";
import { RxJS } from "namespaces/RxJS";
import { CompanySchema, Schema } from "services/db/Schema";
import { ObservableQueryCreator } from "services/db/kysely/ObservableKysely";
import { CustomFieldSubject } from "shared/cf";
import { ASSET_DELIMITER } from "shared/config";
//import { TableExpressionDatabase } from "kysely/dist/esm/parser/table-parser"

//TODO add type
//TODO does the L type, string|null etc, does any of that make sense? or should it just be string
export function procedureLabelExpression<L extends string | null>(location: Expression<L>, asset: Expression<L>, procedureLabel: Expression<L>) {
    return sql`${location} || ${ASSET_DELIMITER} || ${asset}`.$castTo<string>()
}
export function procedureLabelExpression2(eb: ExpressionBuilder<CompanySchema, "locations" | "assets" | "procedures">) {
    return sql`${eb.ref("locations.name")} || ${ASSET_DELIMITER} || ${eb.ref("assets.name")}`.$castTo<string>()
}

export function assetNameExpression<L extends string | null>(location: Expression<L>, asset: Expression<L>) {
    return sql`${location} || ${ASSET_DELIMITER} || ${asset}`.$castTo<null extends L ? string | null : string>()
}

/**
 * Get all custom fields for a subject.
 * @param subject The subject.
 * @returns A query builder for custom fields.
 */
export function getCustomFields(subject?: CustomFieldSubject | undefined) {
    return (kysely: QueryCreator<CompanySchema>) => {
        return kysely.selectFrom("customFields")
            .$call(qc => {
                if (subject === undefined) {
                    return qc
                }
                return qc.where("subject", "=", subject)
            })
            .where("archived", "=", DB_FALSE)
            .orderBy("name")
            .selectAll()
    }
}

export type IndexedLocation = Awaited<ReturnType<typeof indexLocations>>[number]

export async function indexLocations(kysely: QueryCreator<CompanySchema>, names: readonly (string | Nil)[], restrictTo: string | undefined) {
    const locations = await kysely.selectFrom("locations")
        .where("archived", "=", DB_FALSE)
        .where("name", "in", names.filter(Ramda.isNotNil))
        .groupBy("name")
        .select(["id", "name"])
        .execute()
    return locations
}

export type IndexedAsset = Awaited<ReturnType<typeof indexAssets>>[number]

export async function indexAssets(kysely: QueryCreator<CompanySchema>, names: readonly (string | { asset?: string | Nil, location?: string | Nil })[], restrictTo: string | undefined) {
    const assets = await Promise.all(Ramda.splitEvery(256, names).map(async chunk => {
        return await kysely.selectFrom("assets")
            .innerJoin("locations", "locations.id", "assets.locationId")
            .where("assets.archived", "=", DB_FALSE)
            .where(wb => {
                if (chunk.length === 0) {
                    return sql.val(DB_TRUE).$castTo<boolean>()
                }
                return wb.or(chunk.map(name => {
                    if (typeof name === "string") {
                        return wb(assetNameExpression(wb.ref("locations.name"), wb.ref("assets.name")), "=", name)
                    }
                    else {
                        if (name.location === undefined || name.asset === undefined) {
                            return sql.val(DB_FALSE).$castTo<boolean>()
                        }
                        return wb.and([
                            wb("locations.name", "=", name.location),
                            wb("assets.name", "=", name.asset)
                        ])
                    }
                }))
            })
            .where(whereLocationRestrictTo(restrictTo))
            .select([
                "assets.id",
                "assets.name as asset",
                "locations.name as location",
                eb => assetNameExpression(eb.ref("locations.name"), eb.ref("assets.name")).as("name")
            ])
            .execute()
    }))
    return assets.flat()
}

export function assetOptions(kysely: ObservableQueryCreator<CompanySchema>, input: string, restrictTo: string | undefined) {
    return kysely.selectFrom("assets")
        .innerJoin("locations", "locations.id", "assets.locationId")
        .select([
            eb => assetNameExpression(eb.ref("locations.name"), eb.ref("assets.name")).as("value"),
            eb => assetNameExpression(eb.ref("locations.name"), eb.ref("assets.name")).as("label")
        ])
        .where(whereLocationRestrictTo(restrictTo))
        .where(eb => assetNameExpression(eb.ref("locations.name"), eb.ref("assets.name")), "like", "%" + input + "%")
        .where("assets.archived", "=", DB_FALSE)
        .orderBy(eb => assetNameExpression(eb.ref("locations.name"), eb.ref("assets.name")))
        .limit(SELECT_LIMIT)
}

export async function cloneProcedureById(tx: QueryCreator<CompanySchema>, procedureId: ID) {
    const procedure = await tx.selectFrom("procedures").where("id", "=", procedureId).selectAll().executeTakeFirstOrThrow()
    const hazards = await tx.selectFrom("procedureHazards").where("procedureId", "=", procedureId).where("archived", "=", DB_FALSE).selectAll().execute()
    const controlPoints = await tx.selectFrom("controlPoints").where("procedureId", "=", procedureId).where("archived", "=", DB_FALSE).selectAll().execute()
    await tx.insertInto("procedures").values(procedure).execute()
    if (controlPoints.length > 0) {
        await tx.insertInto("controlPoints").values(controlPoints.map(controlPoint => {
            return {
                ...controlPoint,
                id: newId(),
                procedureId: procedure.id,
            }
        })).execute()
    }
    await updateProcedureHazards(tx, {
        procedureId,
        hazardIds: hazards.map(_ => _.hazardId)
    })
}
//TODO replace w above?
export async function cloneProcedure(tx: QueryCreator<CompanySchema>, procedureId: ID, procedure: Insertable<Procedure>) {
    const hazards = await tx.selectFrom("procedureHazards").where("procedureId", "=", procedureId).where("archived", "=", DB_FALSE).selectAll().execute()
    const controlPoints = await tx.selectFrom("controlPoints").where("procedureId", "=", procedureId).where("archived", "=", DB_FALSE).selectAll().execute()
    await tx.insertInto("procedures").values(procedure).execute()
    if (controlPoints.length > 0) {
        await tx.insertInto("controlPoints").values(controlPoints.map(controlPoint => {
            return {
                ...controlPoint,
                id: newId(),
                procedureId: procedure.id,
            }
        })).execute()
    }
    await updateProcedureHazards(tx, {
        procedureId,
        hazardIds: hazards.map(_ => _.hazardId)
    })
}

export const procedureCountsByLocation = (db: QueryCreator<CompanySchema>) => {
    return db.selectFrom("locations")
        .select("id as locationId")
        .select(qb => qb.selectFrom("procedures")
            .select(eb => eb.fn.count<number>("procedures.id").as("count"))
            .innerJoin("assets", "assets.id", "procedures.assetId")
            .whereRef("assets.locationId", "=", "locations.id")
            .where("assets.archived", "=", DB_FALSE)
            .where("procedures.archived", "=", DB_FALSE)
            .as("procedures"))
}
/*
export const assetCountsByLocation = (db: QueryCreator<CompanySchema>) => {
    return db.selectFrom("locations")
        .select("id as locationId")
        .select(qb => qb.selectFrom("assets")
            .select(eb => eb.fn.count<number>("assets.id").as("count"))
            .whereRef("assets.locationId", "=", "locations.id")
            .where("assets.archived", "=", DB_FALSE)
            .as("asse     nnts"))
}
*/

export function companiesQuery(qc: QueryCreator<Schema>) {
    return qc.selectFrom(["users", "companies"])
        .where(sb => {
            return sb(sb.selectFrom("permissions").whereRef("permissions.companyId", "=", "companies.id")
                .whereRef("permissions.userId", "=", "users.id")
                .where("permissions.role", "=", "VIEW")
                .select(eb => eb.fn.count("role").as("found")), ">", 0)
        })
}

export function whereRestrictTo(ex: Expression<string | null>, restrictTo: string | undefined) {
    if (restrictTo === undefined) {
        return sql.val(DB_TRUE).$castTo<boolean>()
    }
    const eb = expressionBuilder()
    return eb.or([
        eb(ex, "like", restrictTo),
        eb(ex, "like", restrictTo + ASSET_DELIMITER + "%")
    ])
}

export function whereSearch(search: string, locationName: Expression<string>, assetName: Expression<string>) {
    const eb = expressionBuilder()
    return eb(
        assetNameExpression(locationName, assetName),
        "like",
        "%" + search + "%",
    )
}

export function whereLocationRestrictTo(restrictTo: string | undefined) {
    return whereRestrictTo(expressionBuilder<CompanySchema, "locations">().ref("locations.name"), restrictTo)
}

export function assetCountByLocation(ex: Expression<ID>) {
    const eb = expressionBuilder<{ assets: Asset }, never>()
    return eb.selectFrom("assets")
        .select(eb => eb.fn.count<number>("assets.id").as("count"))
        .whereRef("assets.locationId", "=", ex)
        .where("assets.archived", "=", DB_FALSE)
}

/**
 * Update a procedure's control points.
 * @param tx A database transaction.
 * @param procedureId The procedure ID.
 * @param controlPoints An array of control points.
 */
export async function updateProcedureControlPoints(tx: QueryCreator<CompanySchema>, procedureId: ID, controlPoints: readonly Omit<Insertable<ControlPoint>, "procedureId">[]) {
    if (controlPoints.length === 0) {
        await tx.updateTable("controlPoints")
            .set({
                archived: DB_TRUE
            })
            .where("procedureId", "=", procedureId)
            .execute()
    }
    else {
        await tx.updateTable("controlPoints")
            .set({
                archived: DB_TRUE
            })
            .where("procedureId", "=", procedureId)
            .where("id", "not in", controlPoints.map(_ => _.id))
            .execute()
        await tx.insertInto("controlPoints")
            .values(controlPoints.map(controlPoint => {
                return {
                    ...controlPoint,
                    procedureId,
                }
            }))
            .onConflict(ob => {
                return ob.column("id").doUpdateSet({
                    step: eb => eb.ref("excluded.step"),
                    number: eb => eb.ref("excluded.number"),
                    isolationLocation: eb => eb.ref("excluded.isolationLocation"),
                    energyMagnitude: eb => eb.ref("excluded.energyMagnitude"),
                    energyMeasureId: eb => eb.ref("excluded.energyMeasureId"),
                    isolationDeviceId: eb => eb.ref("excluded.isolationDeviceId"),
                    energySourceId: eb => eb.ref("excluded.energySourceId"),
                    methodOverride: eb => eb.ref("excluded.methodOverride"),
                    verificationOverride: eb => eb.ref("excluded.verificationOverride"),
                    photoId: eb => eb.ref("excluded.photoId"),
                    barCode: eb => eb.ref("excluded.barCode"),
                    customFields: eb => eb.ref("excluded.customFields"),
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}

interface ProcedureAndHazards {

    readonly procedureId: ID
    readonly hazardIds: readonly ID[] | undefined

}

export async function updateProcedureHazards(tx: QueryCreator<CompanySchema>, rowOrRows: ProcedureAndHazards | readonly ProcedureAndHazards[]) {
    const rows = [rowOrRows].flat()
    await tx.updateTable("procedureHazards")
        .set({
            archived: DB_TRUE
        })
        .where(wb => {
            return wb.or(rows.map(row => {
                const hazardIds = row.hazardIds ?? []
                if (hazardIds.length === 0) {
                    return wb("procedureId", "=", row.procedureId)
                }
                return wb.and([
                    wb("procedureId", "=", row.procedureId),
                    wb("hazardId", "not in", hazardIds)
                ])
            }))
        })
        .execute()
    const hazardIds = rows.flatMap(row => {
        return (row.hazardIds ?? []).map(hazardId => {
            return {
                procedureId: row.procedureId,
                hazardId: hazardId,
                archived: DB_FALSE,
            }
        })
    })
    if (hazardIds.length > 0) {
        await tx.insertInto("procedureHazards")
            .values(hazardIds)
            .onConflict(ob => {
                return ob.columns(["procedureId", "hazardId"]).doUpdateSet({
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}

export async function updateInspectionItemPhotos(tx: QueryCreator<CompanySchema>, rowOrRows: { inspectionItemId: ID, photoIds: readonly ID[] | undefined } | { inspectionItemId: ID, photoIds: readonly ID[] | undefined }[]) {
    const rows = [rowOrRows].flat()
    await tx.updateTable("inspectionItemPhotos")
        .set({
            archived: DB_TRUE
        })
        .where(wb => {
            return wb.or(rows.map(row => {
                const photoIds = row.photoIds ?? []
                if (photoIds.length === 0) {
                    return wb("inspectionItemId", "=", row.inspectionItemId)
                }
                return wb.and([
                    wb("inspectionItemId", "=", row.inspectionItemId),
                    wb("photoId", "not in", photoIds)
                ])
            }))
        })
        .execute()
    const photoIds = rows.flatMap(row => {
        return (row.photoIds ?? []).map(photoId => {
            return {
                inspectionItemId: row.inspectionItemId,
                photoId: photoId,
                archived: DB_FALSE,
            }
        })
    })
    if (photoIds.length > 0) {
        await tx.insertInto("inspectionItemPhotos")
            .values(photoIds)
            .onConflict(ob => {
                return ob.columns(["inspectionItemId", "photoId"]).doUpdateSet({
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}

export interface AuditFindingUpdate extends Omit<Insertable<AuditFinding>, "itemId"> {

    readonly photos?: readonly (ID | Nil)[] | Nil

}

export interface AuditUpdate {

    readonly itemId: ID
    readonly findings?: readonly (AuditFindingUpdate | Nil)[] | Nil

}

export async function updateAuditFindings(tx: QueryCreator<CompanySchema>, records: readonly AuditUpdate[]) {
    await tx.updateTable("auditFindings")
        .set({
            archived: DB_TRUE
        })
        .where("itemId", "in", records.map(record => record.itemId))
        .where("archived", "=", DB_FALSE)
        .$call(qb => {
            if (records.length === 0) {
                return qb
            }
            return qb.where("id", "not in", records.flatMap(record => record.findings).filter(Ramda.isNotNil).map(_ => _.id))
        })
        .execute()
    await tx.updateTable("auditFindingPhotos")
        .set({
            archived: DB_TRUE
        })
        .where(wb => {
            return wb.or(records.flatMap(record => record.findings).filter(Ramda.isNotNil).map(finding => {
                const photoIds = finding.photos?.filter(Ramda.isNotNil) ?? []
                if (photoIds.length === 0) {
                    return wb("findingId", "=", finding.id)
                }
                return wb.and([
                    wb("findingId", "=", finding.id),
                    wb("photoId", "not in", photoIds)
                ])
            }))
        })
        .execute()
    const findings = records.flatMap(record => {
        return record.findings?.filter(Ramda.isNotNil).map(finding => {
            return {
                id: finding.id,
                itemId: record.itemId,
                severity: finding.severity,
                issue: finding.issue,
                correction: finding.correction,
            }
        }) ?? []
    })
    if (findings.length > 0) {
        await tx.insertInto("auditFindings")
            .values(findings)
            .onConflict(ob => {
                return ob.doUpdateSet({
                    severity: eb => eb.ref("excluded.severity"),
                    issue: eb => eb.ref("excluded.issue"),
                    correction: eb => eb.ref("excluded.correction"),
                })
            })
            .execute()
    }
    const photoIds = records.flatMap(record => record.findings).filter(Ramda.isNotNil).flatMap(finding => {
        return (finding.photos?.filter(Ramda.isNotNil) ?? []).map(photoId => {
            return {
                findingId: finding.id,
                photoId: photoId,
                archived: DB_FALSE,
            }
        })
    })
    if (photoIds.length > 0) {
        await tx.insertInto("auditFindingPhotos")
            .values(photoIds)
            .onConflict(ob => {
                return ob.doUpdateSet({
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}
/*
export async function updateAuditFindingPhotos(tx: QueryCreator<CompanySchema>, rows: readonly { readonly findingId: ID, readonly photoIds: readonly ID[] | undefined }[]) {
    await tx.updateTable("auditFindingPhotos")
        .set({
            archived: DB_TRUE
        })
        .where(wb => {
            return wb.or(rows.map(row => {
                const photoIds = row.photoIds ?? []
                if (photoIds.length === 0) {
                    return wb("findingId", "=", row.findingId)
                }
                return wb.and([
                    wb("findingId", "=", row.findingId),
                    wb("photoId", "not in", photoIds)
                ])
            }))
        })
        .execute()
    const photoIds = rows.flatMap(row => {
        return (row.photoIds ?? []).map(photoId => {
            return {
                findingId: row.findingId,
                photoId: photoId,
                archived: DB_FALSE,
            }
        })
    })
    if (photoIds.length > 0) {
        await tx.insertInto("auditFindingPhotos")
            .values(photoIds)
            .onConflict(ob => {
                return ob.doUpdateSet({
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}
*/

//TODO rm
export async function updateInspectionFindingPhotos(tx: QueryCreator<CompanySchema>, rows: { inspectionFindingId: ID, photoIds: readonly ID[] | undefined }[]) {
    await tx.updateTable("inspectionFindingPhotos")
        .set({
            archived: DB_TRUE
        })
        .where(wb => {
            return wb.or(rows.map(row => {
                const photoIds = row.photoIds ?? []
                if (photoIds.length === 0) {
                    return wb("inspectionFindingId", "=", row.inspectionFindingId)
                }
                return wb.and([
                    wb("inspectionFindingId", "=", row.inspectionFindingId),
                    wb("photoId", "not in", photoIds)
                ])
            }))
        })
        .execute()
    const photoIds = rows.flatMap(row => {
        return (row.photoIds ?? []).map(photoId => {
            return {
                inspectionFindingId: row.inspectionFindingId,
                photoId: photoId,
                archived: DB_FALSE,
            }
        })
    })
    if (photoIds.length > 0) {
        await tx.insertInto("inspectionFindingPhotos")
            .values(photoIds)
            .onConflict(ob => {
                return ob.columns(["inspectionFindingId", "photoId"]).doUpdateSet({
                    archived: eb => eb.ref("excluded.archived"),
                })
            })
            .execute()
    }
}

/**
 * Get all template and language combinations.
 * @param qc The query creator.
 * @param templateType The template type.
 * @returns All template and language combinations.
 */
export function getTemplateAndLanguageCombinations(qc: ObservableQueryCreator<CompanySchema>, templateType: TemplateType) {
    return RxJS.combineLatest({
        templates: qc.observe(qc => qc.selectFrom("templates").selectAll().where("archived", "=", DB_FALSE).where("type", "=", templateType).orderBy("name")),
        languages: qc.observe(qc => qc.selectFrom("languages").selectAll().where("archived", "=", DB_FALSE).orderBy("name")),
    }).pipe(
        RxJS.map(data => {
            return data.templates.flatMap(template => {
                return [
                    {
                        template,
                        language: undefined,
                    },
                    ...data.languages.map(language => {
                        return {
                            template,
                            language,
                        }
                    })
                ]
            })
        })
    )
}
