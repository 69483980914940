import { useCallback } from 'react';
import { callOrGet } from 'value-or-factory';
import { useCounter } from './counter.mjs';
import { useThrower } from './thrower.mjs';

/**
 * Returns an object that can be used to track async operations.
 * @returns An object that can be used to track async operations.
 */
function useAsyncTracker() {
    const runs = useCounter();
    const thrower = useThrower();
    return {
        isRunning: runs.count > 0,
        runningCount: runs.count,
        run: (promise) => {
            runs.increment();
            callOrGet(promise).then(() => {
                runs.decrement();
            }, e => {
                runs.decrement();
                thrower(e);
            });
        }
    };
}
function useTrackingAsyncCallback(func) {
    const tracker = useAsyncTracker();
    return {
        isRunning: tracker.isRunning,
        runningCount: tracker.runningCount,
        run: useCallback((...args) => tracker.run(() => func(...args)), [func])
    };
}
//TODO rm?
function useAsyncCallback(func) {
    const tracker = useAsyncTracker();
    return {
        isRunning: tracker.isRunning,
        runningCount: tracker.runningCount,
        run: (...args) => tracker.run(() => func(...args))
    };
}

export { useAsyncCallback, useAsyncTracker, useTrackingAsyncCallback };
