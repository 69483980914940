import { flow } from 'fp-ts/function';
import 'fp-ts/Array';
import { buildError } from './base.mjs';
import { flatMap, failure, of } from './core.mjs';
import 'value-or-factory';
import 'fp-ts/Either';
import 'ramda';
import 'js-base64';
import { split, to1 } from './tuples.mjs';
import 'fp-ts/lib/function';
import 'fp-ts/lib/Array';
import './specialty.mjs';
import 'uuid';

/**
 * This is the base of all validators. Performs a test, throws an error if it fails. If it passes, the original value is returned.
 */
const predicate = (predicates, error = "This value is invalid.") => flatMap((input) => {
    const failures = (Array.isArray(predicates) ? predicates : [predicates]).map(_ => _(input)).filter(_ => !_);
    if (failures.length > 0) {
        return failure(buildError(error, input));
    }
    return of(input);
});
const test = (testers) => flatMap((input) => {
    const errors = buildError([testers].flat().flatMap(tester => tester(input)), input);
    if (errors.length > 0) {
        return failure(buildError(errors, input));
    }
    return of(input);
});
/**
 * Uses another mapper to test, but returns the original value unchanged if successful.
 */
const testWith = (func) => flow(split(func), to1);

export { predicate, test, testWith };
