import { Base64 } from "js-base64";
import { parse, stringify, v4 } from "uuid";
export const newId = () => v4({}, new Uint8Array(16));
export function binaryToId(binary) {
    if (binary.byteLength === 16) {
        return binary;
    }
    else {
        throw new Error("This is not a valid ID (byte length is " + binary.byteLength + ").");
    }
}
export function idToBinary(id) {
    return id;
}
export const printId = (id) => {
    return stringify(id);
};
export const printIdBase64 = (id) => {
    return Base64.fromUint8Array(id, true);
};
export function parseId(id) {
    return binaryToId(parse(id));
}
export function parseIdBase64(id) {
    return binaryToId(Base64.toUint8Array(id));
}
export function parseIdIfNecessary(id) {
    if (typeof id !== "string") {
        return id;
    }
    return parseId(id);
}
export function isId(id) {
    try {
        if (ArrayBuffer.isView(id)) {
            return true;
        }
        return false;
    }
    catch (e) {
        return false;
    }
}
