
import Asset from "domain/entities/Asset"
import AssetType from "domain/entities/AssetType"
import Audit from "domain/entities/Audit"
import AuditFinding from "domain/entities/AuditFinding"
import AuditFindingPhoto from "domain/entities/AuditFindingPhoto"
import AuditItem from "domain/entities/AuditItem"
import Company from "domain/entities/Company"
import CompanySettings from "domain/entities/CompanySettings"
import ControlPoint from "domain/entities/ControlPoint"
import ControlPointTemplate from "domain/entities/ControlPointTemplate"
import CustomField from "domain/entities/CustomField"
import EnergyMeasure from "domain/entities/EnergyMeasure"
import EnergySource from "domain/entities/EnergySource"
import Hazard from "domain/entities/Hazard"
import Inspection from "domain/entities/Inspection"
import InspectionFinding from "domain/entities/InspectionFinding"
import InspectionFindingPhoto from "domain/entities/InspectionFindingPhoto"
import InspectionItem from "domain/entities/InspectionItem"
import InspectionItemPhoto from "domain/entities/InspectionItemPhoto"
import InspectionReason from "domain/entities/InspectionReason"
import IsolationDevice from "domain/entities/IsolationDevice"
import Language from "domain/entities/Language"
import Line from "domain/entities/Line"
import Location from "domain/entities/Location"
import { Permission } from "domain/entities/Permission"
import Preset from "domain/entities/Preset"
import Procedure from "domain/entities/Procedure"
import ProcedureHazard from "domain/entities/ProcedureHazard"
import ProcedureTemplate from "domain/entities/ProcedureTemplate"
import ProcedureType from "domain/entities/ProcedureType"
import Severity from "domain/entities/Severity"
import Status from "domain/entities/Status"
import Template from "domain/entities/Template"
import Translation from "domain/entities/Translation"
import Upload from "domain/entities/Upload"
import User from "domain/entities/User"
import { Meta } from "domain/Meta"
import { SelectType } from "kysely"

export type SyncSchema = Record<string, Meta & Record<string, unknown>>

export const SYNC_TABLES: (keyof SyncSchema & keyof Schema)[] = [
    "uploads",
    "templates",
    "assetTypes",
    "statuses",
    "severities",
    "procedureTypes",
    "inspectionReasons",
    "energySources",
    "energyMeasures",
    "isolationDevices",
    "hazards",
    "customFields",
    "procedureTemplates",
    "controlPointTemplates",
    "companySettings",
    "locations",
    "assets",
    "procedures",
    "procedureHazards",
    "controlPoints",
    "languages",
    "translations",
    "inspections",
    "inspectionItems",
    "inspectionItemPhotos",
    "inspectionFindings",
    "inspectionFindingPhotos",
    "audits",
    "auditItems",
    "auditFindings",
    "auditFindingPhotos",
]

export type SqliteTables = {

    readonly pragma_user_version: {
        readonly user_version: unknown
    }

    readonly pragma_table_info: {
        readonly arg: string
        readonly name: string
        readonly type: string
        readonly notnull: 0 | 1
        readonly pk: 0 | 1
    }

    readonly sqlite_master: {
        readonly name: string
        readonly type: string
    }

}

type RootTables = {

    readonly companies: Company
    readonly users: User
    readonly permissions: Permission

}

type CompanyTables = {

    readonly assets: Asset
    readonly assetTypes: AssetType
    readonly audits: Audit
    readonly auditItems: AuditItem
    readonly auditFindings: AuditFinding
    readonly auditFindingPhotos: AuditFindingPhoto
    readonly companySettings: CompanySettings
    readonly controlPoints: ControlPoint
    readonly controlPointTemplates: ControlPointTemplate
    readonly customFields: CustomField
    readonly energyMeasures: EnergyMeasure
    readonly energySources: EnergySource
    readonly hazards: Hazard
    readonly inspections: Inspection
    readonly inspectionFindings: InspectionFinding
    readonly inspectionFindingPhotos: InspectionFindingPhoto
    readonly inspectionItems: InspectionItem
    readonly inspectionItemPhotos: InspectionItemPhoto
    readonly inspectionReasons: InspectionReason
    readonly isolationDevices: IsolationDevice
    readonly languages: Language
    readonly lines: Line
    readonly locations: Location
    readonly presets: Preset
    readonly procedures: Procedure
    readonly procedureHazards: ProcedureHazard
    readonly procedureTemplates: ProcedureTemplate
    readonly procedureTypes: ProcedureType
    readonly severities: Severity
    readonly statuses: Status
    readonly templates: Template
    readonly translations: Translation
    readonly uploads: Upload

}

export type CompanySchema = SqliteTables & RootTables & CompanyTables
export type Schema = SqliteTables & RootTables & { [K in keyof CompanyTables]: { [F in keyof CompanyTables[K]]: SelectType<CompanyTables[K][F]> } & Meta }
