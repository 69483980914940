import { equals } from 'ramda';
import { useRef, useEffect, useMemo } from 'react';

function useIsFirstMount() {
    const isFirst = useRef(true);
    if (isFirst.current) {
        isFirst.current = false;
        return true;
    }
    return isFirst.current;
}
function useDeepCompareMemo(factory, deps) {
    const ref = useRef(undefined);
    if (ref.current === undefined || !equals(deps, ref.current)) {
        ref.current = deps;
    }
    return useMemo(factory, ref.current);
}
function useDeepCompareConstant(value) {
    return useDeepCompareMemo(() => value, [value]);
}
function useDeepCompareEffect(effect, deps) {
    const ref = useRef(undefined);
    if (ref.current === undefined || !equals(deps, ref.current)) {
        ref.current = deps;
    }
    return useEffect(effect, ref.current);
}

export { useDeepCompareConstant, useDeepCompareEffect, useDeepCompareMemo, useIsFirstMount };
