
import { useBaseUrl } from "adapters/hooks"
import { Fragment, useContext, useEffect } from "react"
import { Stack } from "react-bootstrap"
import { When } from "react-if"
import { Link } from "react-router-dom"
import { useDataManager } from "services/Core"
import { errorToString, throwError } from "shared/misc"
import { Maybe, useAsyncCallback } from "state-hooks"
import ErrorPage from "ui/components/ErrorPage"
import IconButton from "ui/components/IconButton"
import IconText from "ui/components/IconText"
import AuthContext from "ui/contexts/AuthContext"
import DebugContext from "ui/contexts/DebugContext"
import { DASHBOARD_ICON, DELETE_ICON, DOWNLOAD_ICON, ERROR_ICON, LOGOUT_ICON, RETRY_ICON } from "ui/icons"

export interface ErrorBoundaryPageProps {

    readonly error: unknown
    readonly resetErrorBoundary?: (() => void) | undefined

}

export default function ErrorBoundaryPage(props: ErrorBoundaryPageProps) {
    const debug = useContext(DebugContext)
    const auth = useContext(AuthContext)
    const baseUrl = useBaseUrl()
    useEffect(() => {
        console.error(props.error)
    }, [
        props.error
    ])
    const buttons = (
        <Fragment>
            <IconButton onClick={throwError(props.error)} variant="warning" icon={ERROR_ICON}>Throw</IconButton>
            <Maybe value={props.resetErrorBoundary}>
                {
                    retry => {
                        return <IconButton variant="success" onClick={retry} icon={RETRY_ICON}>Retry</IconButton>
                    }
                }
            </Maybe>
            <When condition={debug && auth !== undefined}>
                <Dump />
            </When>
            <Maybe value={auth}>
                {
                    auth => {
                        return (
                            <Fragment>
                                <IconButton onClick={auth.logOut} busy={auth.loggingOut} icon={LOGOUT_ICON}>Log Out</IconButton>
                            </Fragment>
                        )
                    }
                }
            </Maybe>
            <Link to={baseUrl} className="btn btn-secondary"><IconText {...DASHBOARD_ICON}>Return to Dashboard</IconText></Link>
        </Fragment>
    )
    const actions = (
        <div className="d-inline-flex">
            <div className="d-none d-md-block"><Stack gap={3} direction="horizontal">{buttons}</Stack></div>
            <div className="d-md-none"><Stack gap={3}>{buttons}</Stack></div>
        </div>
    )
    return (
        <ErrorPage title={errorToString(props.error)} actions={actions}>
            {
                (() => {
                    if (props.error instanceof Error) {
                        return <code>{props.error.stack}</code>
                    }
                })()
            }
        </ErrorPage>
    )
}

function Dump() {
    const dataManager = useDataManager()
    const exportDb = useAsyncCallback(() => dataManager.export())
    const clear = useAsyncCallback(() => dataManager.clear())
    return (
        <Fragment>
            <IconButton variant="info" onClick={exportDb.run} busy={exportDb.isRunning} icon={DOWNLOAD_ICON}>Dump DB</IconButton>
            <IconButton variant="danger" onClick={clear.run} busy={clear.isRunning} icon={DELETE_ICON}>Delete DB</IconButton>
        </Fragment>
    )
}
