import { PropsWithChildren } from "react"
import { LazyContext } from "react-lazy"
import { INTERNAL_LAZY } from "shared/locallazy"

export type InternalBodyProps = PropsWithChildren

export default function InternalBody(props: PropsWithChildren) {
    return (
        <div>
            <LazyContext.Provider value={INTERNAL_LAZY}>
                {props.children}
            </LazyContext.Provider>
        </div>
    )
}
