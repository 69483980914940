import { separate } from 'fp-ts/Array';
import { flow } from 'fp-ts/function';
import { loop } from './internal.mjs';
import { path } from './path.mjs';
import { cast, typed } from './types.mjs';
import 'js-base64';
import 'value-or-factory';
import { map, flatMap, failure, of, chain, flat } from './core.mjs';
import 'fp-ts/Either';
import 'ramda';
import { on2 } from './tuples.mjs';
import 'fp-ts/lib/function';
import 'fp-ts/lib/Array';
import './specialty.mjs';
import 'uuid';

/**
 * Creates a mapper that turns an object of results into a @link Result of an object.
 */
const flattenObject = () => {
    return flow(map(Object.entries), loop(() => chain(value => on2(flow(flat(), path(value[0] ?? []))))), map(Object.fromEntries), (cast));
};
/**
 * Creates a mapper that turns an array of results into a @link Result of an array.
 */
const flattenArray = () => flow((typed), flatMap(input => {
    const separated = separate(input);
    if (separated.left.length > 0) {
        return failure(separated.left.flat());
    }
    else {
        return of(separated.right);
    }
}));

export { flattenArray, flattenObject };
