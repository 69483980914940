import * as E from 'fp-ts/Either';
export { isLeft, isRight } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
export { flow, pipe } from 'fp-ts/function';
import { equals } from 'ramda';
import { callOrGet } from 'value-or-factory';
import { arrayOrElement } from './internal.mjs';

/**
 * Returns true if this result is a success.
 */
const isSuccess = (result) => E.isRight(result);
/**
 * Returns true if this result is a failure.
 */
const isFailure = (result) => E.isLeft(result);
/**
 * Creates a failure result from an error.
 */
function failure(error) {
    return E.left([error].flat());
}
/**
 * Creates a success result from a value.
 */
const success = (value) => E.right(value);
/**
 * A mapper that transforms a value.
 */
const map = (func) => E.map(func);
/**
 * Maps the failure side of a transformation result.
 */
const mapFail = (func) => E.mapLeft(func);
/**
 * Create a new result from a value.
 */
const of = (value) => E.right(value);
/**
 * Recovers from the failure side of a transformation result.
 */
const orElse = (orElse) => (result) => E.orElseW((errors) => callOrGet(orElse, errors))(result);
/**
 * Takes a simple function that converts from I to O or generates an error.
 */
const flatMap = (func) => E.chain(func);
/**
 * Takes a simple function that converts from I to O or generates an error.
 */
const flat = () => flatMap((result) => result);
/**
 * Takes a simple function that uses the input value to generate an additional mapper that is then executed.
 */
const chain = (func) => E.chain((value) => pipe(value, E.of, func(value)));
/**
 * Maps each error in a transformation failure.
 */
const mapEachError = (func) => mapFail(_ => _.map(func));
/**
 * Maps each error in a transformation failure.
 */
const mapErrorAt = (path, func) => mapFail(_ => _.map(error => equals(arrayOrElement(error.path ?? []), arrayOrElement(path)) ? func(error) : error));
/**
 * Tap into a mop flow (usually used for logging).
 */
const tap = (func) => map((value) => {
    func(value);
    return value;
});
/**
 * Tap into a mop flow on failure (usually used for logging).
 */
const tapFail = (func) => mapFail(errors => {
    func(errors);
    return errors;
});

export { chain, failure, flat, flatMap, isFailure, isSuccess, map, mapEachError, mapErrorAt, mapFail, of, orElse, success, tap, tapFail };
