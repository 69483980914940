import 'fp-ts/function';
import { arrayOrElement } from './internal.mjs';
import { mapFail } from './core.mjs';
import 'value-or-factory';
import 'fp-ts/Either';
import 'ramda';
import 'js-base64';
import 'fp-ts/Array';
import { errorAt, formatPath } from './formatting.mjs';
import 'fp-ts/lib/function';
import 'fp-ts/lib/Array';
import './specialty.mjs';
import 'uuid';

/**
 * Prepend a path tree to all errors.
 */
const path = (paths) => mapFail(errors => {
    return errors.map(error => ({ ...error, path: [...arrayOrElement(paths), ...arrayOrElement(error.path ?? []) ?? []] }));
});
/**
 * Truncate the error path.
 */
const truncatePaths = () => {
    return mapFail(errors => {
        return errors.map(error => {
            return {
                //path: [] satisfies ErrorPath[],
                value: error.value,
                //TODO make formatting optional?
                message: errorAt(formatPath(error.path), error.message),
            };
        });
    });
};

export { path, truncatePaths };
