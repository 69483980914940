import { useState, useEffect } from 'react';

/**
 * Returns a convenience method to throw an exception that is caught by the nearest error boundary.
 * @returns A method to throw an exception.
 */
function useThrower() {
    const [exception, setException] = useState();
    useEffect(() => {
        if (exception !== undefined) {
            throw exception;
        }
    }, [
        exception
    ]);
    return setException;
}

export { useThrower };
