import * as E from 'fp-ts/Either';
import { buildError } from './base.mjs';
import { flatMap } from './core.mjs';
import { testWith } from './test.mjs';

/**
 * Tries to execute a function, catching errors. Returns a result containing the return value of the function.
 */
const tryCatch = (func, message = error => `${error[0]}`.replace(/\.$/, "")) => flatMap((value) => {
    return E.tryCatch(() => func(value), e => buildError(message, [e, value]));
});
/**
 * Does validation, catching errors. If no errors caught, returns a result containing the initial value instead of the return value of the validation function.
 */
const testTryCatch = (func, message = error => `${error[0]}`) => {
    return testWith(tryCatch(func, message));
};

export { testTryCatch, tryCatch };
