import { faEllipsis } from "@fortawesome/pro-regular-svg-icons"
import { Break } from "adapters"
import { props } from "ramda"
import { Fragment, ReactNode } from "react"
import { ButtonProps, Nav, NavDropdown } from "react-bootstrap"
import { ReloadingProps } from "react-lazy"
import CollapsingIcon from "shared/CollapsingIcon"
import InternalBody from "shared/layout/InternalBody"
import InternalLayout from "shared/layout/InternalLayout"
import GridLoading from "shared/locallazy/GridLoading"
import IconButton from "ui/components/IconButton"
import IconText from "ui/components/IconText"
import { LAZY_DELAY } from "ui/config"
import FormOffset from "ui/form/FormOffset"
import FormRow from "ui/form/FormRow"
import { LOADING_ICON } from "ui/icons"
import FormLoading from "ui/lazy/FormLoading"
import LoadingBar from "ui/lazy/LoadingBar"
import LoadingIcon from "ui/lazy/LoadingIcon"
import ReloadingBox from "ui/lazy/ReloadingBox"
import ReloadingPage from "ui/lazy/ReloadingPage"

/**
 * A reloading component that shows nothing and doesn't wrap the children.
 */
export const INVISIBLE_RELOADING = (props: ReloadingProps) => <Fragment children={props.children} />

/**
 * The lazy overriddes for the user interface once logged in.
 */
export const INTERNAL_FULL_PAGE_LAZY = {
    onLoading: () => <LoadingBar />,
    onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
    delay: LAZY_DELAY,
}

/**
 * The lazy overriddes for the user interface once logged in.
 */
export const INTERNAL_LAZY = {
    onLoading: () => <InternalBody><LoadingBar /></InternalBody>,
    onReloading: (props: ReloadingProps) => <ReloadingBox {...props} />,
    delay: LAZY_DELAY,
}

/**
 * The lazy overriddes for the user interface once logged in.
 */
export function internalPageLazy(title: ReactNode = "Loading") {
    return {
        onLoading: () => <InternalLayout title={<IconText icon={faEllipsis} beatFade position="right">{title}</IconText>}><InternalBody><LoadingBar /></InternalBody></InternalLayout>,
        onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
        delay: LAZY_DELAY,
    }
}

/**
 * Lazy overrides for grids.
 */
export const GRID_LAZY = {
    onLoading: () => <GridLoading />,
    onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
    delay: LAZY_DELAY,
}

/**
 * Lazy overrides for form fields.
 */
export const FORM_LAZY = {
    onLoading: () => <FormLoading />,
    onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
    delay: LAZY_DELAY,
}

export const FORM_OFFSET_LAZY = {
    onLoading: () => <FormOffset><FormLoading {...props} /></FormOffset>,
    onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
    delay: LAZY_DELAY,
}

export function formRowLazy(label: ReactNode) {
    return {
        onLoading: () => <FormRow label={label}><FormLoading {...props} /></FormRow>,
        onReloading: (props: ReloadingProps) => <ReloadingPage {...props} />,
        delay: LAZY_DELAY,
    }
}

export function buttonLazy(title: ReactNode, props?: ButtonProps | undefined) {
    return {
        onLoading: () => <IconButton icon={LOADING_ICON} children={title} disabled variant="light" {...props} />,
        onReloading: (props: ReloadingProps) => <ReloadingBox {...props} />,
        delay: LAZY_DELAY,
    }
}

export const ICON_LAZY = {
    onLoading: () => <LoadingIcon />,
    onReloading: INVISIBLE_RELOADING,
    delay: LAZY_DELAY,
}

export const ICON_LAZY_SHOW_RELOADING = {
    onLoading: () => <LoadingIcon />,
    onReloading: INVISIBLE_RELOADING,
    delay: LAZY_DELAY,
}

/**
 * Creates lazy overrides for a nav item.
 * @param title The title of the nav item.
 * @param collapse The collapse breakpoint.
 * @returns A lazy overrides object.
 */
export function navLazy(title: string, collapse: Break = "xl") {
    return {
        onLoading: () => <NavLoading collapse={collapse} title={title} />,
        //TODO deprecated   distinguishReloading: false,
        delay: LAZY_DELAY,
    }
}

export interface NavLoadingProps {

    readonly collapse: Break
    readonly title: string

}

export const NavLoading = (props: NavLoadingProps) => {
    return <Nav.Item className="nav-padding disabled"><CollapsingIcon {...LOADING_ICON} collapse={props.collapse} title={props.title} /></Nav.Item>
}

/**
 * Creates lazy overrides for a nav dropdown item.
 * @param title The title of the nav dropdown item.
 * @param collapse The collapse breakpoint.
 * @returns A lazy overrides object.
 */
export function navDropdownLazy(title: string = "Loading...", collapse: Break = "lg") {
    return {
        onLoading: () => <NavDropdownLoading collapse={collapse} title={title} />,
        delay: LAZY_DELAY,
        //TODO deprecated distinguishReloading: false,
    }
}

export interface NavDropdownLoadingProps {

    readonly collapse: Break
    readonly title: string

}

export const NavDropdownLoading = (props: NavDropdownLoadingProps) => {
    return <NavDropdown disabled title={<CollapsingIcon {...LOADING_ICON} collapse={props.collapse} title={props.title} />}><Fragment /></NavDropdown>
}

export function navDropdownItemLazy(title: string = "Loading...", collapse: Break = "lg") {
    return {
        onLoading: () => <NavDropdown.Item disabled children={<CollapsingIcon {...LOADING_ICON} collapse={collapse} title={title} />} />,
        onReloading: INVISIBLE_RELOADING,
        delay: LAZY_DELAY,
    }
}
