
import GlobalContext from "adapters/contexts/GlobalContext"
import Split from "app/Split"
import { BASE_URL, IS_DEBUG, IS_DEV } from "config"
import { useMemo } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Asyncified, LazyContext } from "react-lazy"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { FormDefaults } from "react-tform"
import { ToastContainer } from "react-toastify"
import "scss/App.scss"
import Global from "services/Global"
import ErrorBoundaryPage from "shared/ErrorBoundaryPage"
import { APP_NAME } from "shared/config"
import { INVISIBLE_RELOADING } from "shared/locallazy"
import { useMediaDevicesPromise, useMemoOnce } from "state-hooks"
import { scanCamera } from "ui/camera/index"
import CameraContext from "ui/contexts/CameraContext"
import DebugContext from "ui/contexts/DebugContext"
import StoreContext from "ui/contexts/StoreContext"
import { PAGE_LAZY } from "ui/lazy"
import WithTitle from "ui/title/WithTitle"

/**
 * The main application component.
 */
export default function App() {
    const global = useMemoOnce(() => Global.create(window))
    const devices = useMediaDevicesPromise()
    const load = useMemo(async () => ({ global: await global, devices: await devices }), [global, devices])
    return (
        <WithTitle title={APP_NAME}>
            <div className="d-flex flex-column min-vh-100">
                <LazyContext.Provider value={PAGE_LAZY}>
                    <Asyncified promise={load} overrides={{ onReloading: INVISIBLE_RELOADING, loadingMessage: "Initializing..." }}>
                        {
                            loaded => {
                                const camera = {
                                    show: IS_DEV || loaded.devices.filter(device => device.kind === "videoinput").length > 0,
                                    scan: scanCamera,
                                }
                                return (
                                    <StoreContext.Provider value={loaded.global.store}>
                                        <FormDefaults.Provider value={{ change: "validate" }}>
                                            <GlobalContext.Provider value={loaded.global}>
                                                <CameraContext.Provider value={camera}>
                                                    <BrowserRouter future={{ v7_relativeSplatPath: true }} basename={BASE_URL}>
                                                        <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
                                                            <Routes>
                                                                <Route path="debug/*" element={<DebugContext.Provider value={true} children={<Split />} />} />
                                                                <Route path="nodebug/*" element={<DebugContext.Provider value={false} children={<Split />} />} />
                                                                <Route path="*" element={<DebugContext.Provider value={IS_DEBUG} children={<Split />} />} />
                                                            </Routes>
                                                        </ErrorBoundary>
                                                        <ToastContainer pauseOnFocusLoss={false} />
                                                    </BrowserRouter>
                                                </CameraContext.Provider>
                                            </GlobalContext.Provider>
                                        </FormDefaults.Provider>
                                    </StoreContext.Provider>
                                )
                            }
                        }
                    </Asyncified>
                </LazyContext.Provider>
            </div>
        </WithTitle>
    )
}
