import { flow, pipe } from 'fp-ts/function';
import { loop } from './internal.mjs';
import { typed } from './types.mjs';
import 'js-base64';
import 'value-or-factory';
import { map, flatMap } from './core.mjs';
import 'fp-ts/Array';
import { exec } from './exec.mjs';
import 'fp-ts/Either';
import 'ramda';
import 'fp-ts/lib/function';
import 'fp-ts/lib/Array';
import './specialty.mjs';
import 'uuid';

/**
 * Split a value into itself twice as a tuple.
 */
const clone = () => map((_) => [_, _]);
/**
 * Split a value into a tuple using another mapper on the second half only.
 */
const split = (mapper) => flow(clone(), on2(mapper));
/**
 * Map a value using two mappers, split the result into a tuple.
 */
const bisect = (mapper1, mapper2) => flow(clone(), on1(mapper1), on2(mapper2));
/**
 * Swaps a tuple's elements.
 */
const swap = () => map((_) => [_[1], _[0]]);
/**
 * Maps the first half of a tuple only. Leaves the second intact.
 */
const on1 = (mapper) => flow((typed), flatMap(value => pipe(exec(value[0], mapper), map(_ => [_, value[1]]))));
/**
 * Maps the first half of a tuple array only. Leaves the second intact.
 */
const on1s = (mapper) => flow((typed), loop(index => on1(mapper(index))));
/**
 * Turns a tuple into its first value.
 */
const to1 = () => map((_) => _[0]);
/**
 * Maps the second half of a tuple only. Leaves the first intact.
 */
const on2 = (mapper) => flow((typed), flatMap(value => pipe(exec(value[1], mapper), map(_ => [value[0], _]))));
/**
 * Maps the second half of a tuple array only. Leaves the first intact.
 */
const on2s = (mapper) => flow((typed), loop(index => on2(mapper(index))));
/**
 * Turns a tuple into its second value.
 */
const to2 = () => map((_) => _[1]);

export { bisect, clone, on1, on1s, on2, on2s, split, swap, to1, to2 };
