import { useContext, useEffect } from 'react';
import { callOrGet } from 'value-or-factory';
import { buildErrors } from './errors.mjs';
import { FormFieldImpl } from './field.mjs';
import { FORM_HOOK_KEYS, useFormHook } from './hooks.mjs';
import { execAction } from './internal.mjs';
import { FormDefaults } from './options.mjs';
import { useFormState, initialFormState } from './state.mjs';
import { useDeepCompareEffect } from './util.mjs';

/**
 * Generate a form.
 * @typeParam T The value type.
 * @param options Form options.
 * @returns A form context.
 */
function useForm(options) {
    // Build options and state.
    const [state, setState] = useFormState(options);
    const defaults = useContext(FormDefaults);
    // Validate function.
    const doValidate = async () => {
        try {
            if (options.validate !== undefined) {
                const errors = buildErrors(await options.validate(state.value));
                setState(state => {
                    return {
                        ...state,
                        lastValidated: new Date(),
                        isValid: errors.length === 0,
                        isInvalid: errors.length > 0,
                        errors
                    };
                });
            }
        }
        catch (exception) {
            setState(state => {
                return {
                    ...state,
                    exception
                };
            });
        }
    };
    // Submit function.
    const doSubmit = async () => {
        if (!state.canSubmit || options.disabled) {
            setState(state => {
                return {
                    ...state,
                    lastSubmitted: new Date(),
                    lastSubmitResult: false,
                };
            });
            return;
        }
        try {
            const value = state.value;
            const errors = buildErrors(await options.submit(state.value));
            setState(state => {
                return {
                    ...state,
                    lastSubmitted: new Date(),
                    lastSubmitResult: errors.length === 0,
                    submittedValue: value,
                    errors
                };
            });
        }
        catch (exception) {
            setState(state => {
                return {
                    ...state,
                    exception
                };
            });
            return false;
        }
    };
    // Initialization function. Can be called manually but generally won't be.
    const initialize = (value) => setState(initialFormState(value));
    useDeepCompareEffect(() => {
        initialize(state.initialValue);
    }, [
        state.initialValue
    ]);
    useEffect(() => {
        if (state.lastValidateRequested === undefined) {
            return;
        }
        doValidate();
    }, [
        state.lastValidateRequested
    ]);
    useEffect(() => {
        if (state.lastSubmitRequested === undefined) {
            return;
        }
        doSubmit();
    }, [
        state.lastSubmitRequested
    ]);
    // Revert to the initial data.
    const submit = (event) => {
        event?.preventDefault();
        setState(state => {
            return {
                ...state,
                lastSubmitRequested: new Date(),
            };
        });
    };
    const validate = () => {
        setState(state => {
            return {
                ...state,
                lastValidateRequested: new Date(),
            };
        });
    };
    const setValue = (value) => {
        //TODO we need to separate out "validated" vs "unknown"
        //the LAST validation might be different from the current validation
        //for example, on a non-auto-validating form, if you change a value, a new validation is not triggered - however the form may no longer be valid (we dont know)
        //so we need a "lastValidation" but also a "isValid" - which might be unknown separate
        //this is because we dont want to reset form state, causing flickering, if we re-validate and a new error is generated
        setState(state => {
            return {
                ...state,
                value: callOrGet(value, state.value),
                lastChanged: new Date(),
            };
        });
    };
    const setErrors = (errors) => {
        setState(state => {
            return {
                ...state,
                errors: buildErrors(callOrGet(errors, state.errors ?? []))
            };
        });
    };
    // The root form group.
    const group = FormFieldImpl.from({
        //  path: [],
        value: state.value,
        setValue,
        errors: state.errors,
        setErrors,
        disabled: options.disabled,
        blur: () => setState(state => ({ ...state, lastBlurred: new Date() })),
        commit: () => setState(state => ({ ...state, lastCommitted: new Date() })),
        focus: () => setState(state => ({ ...state, lastFocused: new Date() })),
    });
    const handlers = {
        onKeyUp: (() => {
            if (options.disabled) {
                return;
            }
            return (event) => {
                if (event.key !== "Enter") {
                    return;
                }
                const target = event.target;
                if (target.tagName === "INPUT") {
                    submit();
                }
            };
        })()
    };
    const context = {
        ...state,
        ...group,
        initialize,
        submit,
        validate,
        setErrors,
        handlers,
    };
    const allActions = { ...defaults, ...options.on };
    FORM_HOOK_KEYS.forEach(item => {
        useFormHook(context, item, () => {
            const action = allActions[item];
            if (action === undefined) {
                return;
            }
            execAction(context, action);
        });
    });
    if (state.exception !== undefined) {
        throw state.exception;
    }
    return context;
}

export { useForm };
