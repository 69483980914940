import { DB_FALSE, DB_TRUE } from "adapters"
import { binaryToId, ID, idToBinary, printId } from "adapters/ids"
import { UPLOADS_URL } from "config"
import { QueryCreator } from "kysely"
import { Array } from "namespaces/Array"
import { Either } from "namespaces/Either"
import { Ramda } from "namespaces/Ramda"
import { Schema } from "services/db/Schema"
import FileStore from "services/store/FileStore"
import * as GenQLSync from "shared/genql/sync"
import { checkResponse, isTemporaryException } from "shared/http"
import { maybe } from "shared/maybe"
import { uploadUrl } from "shared/upload"
import { Hasura } from "ui/services/Hasura"

export default class {

    constructor(private readonly kysely: QueryCreator<Schema>,
        private readonly hasura: Hasura.Client<GenQLSync.Client>,
        private readonly store: FileStore) {
    }

    /**
     * Which photos to keep when clearing out local photos?
     * @returns A list of IDs.
     */
    private async keep() {
        const a = this.kysely.selectFrom("energySources").select(["companyId", "iconId as id"]).where("archived", "=", DB_FALSE)//.whereRef("iconId", "=", "uploads.id")
        const b = this.kysely.selectFrom("isolationDevices").select(["companyId", "iconId as id"]).where("archived", "=", DB_FALSE)//.whereRef("iconId", "=", "uploads.id")
        const c = this.kysely.selectFrom("isolationDevices").select(["companyId", "videoId as id"]).where("archived", "=", DB_FALSE)//.whereRef("videoId", "=", "uploads.id")
        const d = this.kysely.selectFrom("controlPointTemplates").select(["companyId", "photoId as id"]).where("archived", "=", DB_FALSE)//.whereRef("photoId", "=", "uploads.id")
        const e = this.kysely.selectFrom("hazards").select(["companyId", "iconId as id"]).where("archived", "=", DB_FALSE)//.whereRef("iconId", "=", "uploads.id")
        const ids = await c.union(b).union(a).union(d).union(e).execute()
        return ids.map(row => {
            if (row.id === null) {
                return
            }
            return [
                row.companyId,
                row.id,
            ] as const
        }).filter(Ramda.isNotNil)
    }
    private async have() {
        return (await this.store.keys()).map(([companyId, id]) => [companyId, binaryToId(id)] as const)
    }

    async download() {
        const have = await this.have()
        const keep = await this.keep()
        console.log("[Sync/File] Downloading files...", { keep, have })
        const need = Ramda.difference(keep, have)
        if (need.length === 0) {
            return {
                filesDownloaded: 0
            }
        }
        console.log("[Sync/File] There are " + keep.length + " files we to keep. We have " + have.length + " of them. We need " + need.length + ".")
        const pull = await this.kysely.selectFrom("uploads")
            .where("uploads.present", "=", DB_TRUE)
            .where(wb => {
                return wb.or(need.map(item => {
                    return wb.and([wb("companyId", "=", item[0]), wb("id", "=", item[1])])
                }))
            })
            .select([
                "id",
                "companyId"
            ])
            .execute()
        const results = await Promise.all(pull.map(async id => {
            try {
                const url = uploadUrl(UPLOADS_URL, id.companyId, printId(id.id))
                const result = await fetch(url, {
                    cache: "no-cache",
                })
                if (result.status !== 200) {
                    return Either.left({
                        ...id,
                        reason: result.statusText
                    })
                }
                else {
                    return Either.right({
                        ...id,
                        data: await result.blob()
                    })
                }
            }
            catch (reason) {
                return Either.left({
                    ...id,
                    reason
                })
            }
        }))
        const separated = Array.separate(results)
        await this.store.setMulti(separated.right.map(item => [[item.companyId, idToBinary(item.id)], item.data]))
        //await this.dexie.blobs.bulkPut(separated.right)
        if (separated.left.length > 0) {
            console.warn("[Sync/File] Failed to download " + separated.left.length + " files.", separated.left)
            throw new Error("Failed to download " + separated.left.length + " files.")
        }
        return {
            filesDownloaded: separated.right.length
        }
    }

    async upload() {
        console.log("[Sync/File] Running upload process...")
        const have = await this.have()
        //TODO breaks on large number of uploads
        //const have = Ramda.range(0, 10000).map(() => [0, newId()] as const)
        const push = await this.kysely.selectFrom("uploads")
            .where("uploads.present", "=", DB_FALSE)
            .where(wb => {
                return wb.or(
                    Ramda.splitEvery(512, have).map(innerChunk => {
                        return wb.or(innerChunk.map(item => {
                            return wb.and([wb("id", "=", item[1]), wb("companyId", "=", item[0])])
                        }))
                    })
                )
            })
            .select([
                "id",
                "companyId",
            ])
            .execute()
        const ids = push.map(item => [item.companyId, item.id] as const)
        const data = (await this.store.getMulti(ids.map(([companyId, id]) => [companyId, idToBinary(id)] as const))).filter(Ramda.isNotNil)
        //const data = (await this.dexie.blobs.bulkGet(push.map(item => [item.id, item.companyId]))).filter(D.isNotNil)
        console.log("[Sync/File] Uploading " + push.length + " files...", push)
        const results = await this.up(Ramda.zip(ids, data))
        const separated = Array.separate(results)
        const firstFailure = separated.left.at(0)
        if (firstFailure !== undefined) {
            console.warn("Failed to upload " + separated.left.length + " files.", separated.left)
            throw new Error("Failed to upload " + separated.left.length + " files.", { cause: firstFailure.reason })
        }
        return {
            filesUploaded: separated.right.length
        }
    }

    async clean() {
        console.log("[Sync/File] Cleaning files...")
        const have = await this.have()
        const keep = await this.keep()
        const discard = Ramda.difference(have, keep)
        if (discard.length === 0) {
            console.log("[Sync/File] No files to discard.")
            return {
                filesDeleted: 0
            }
        }
        const present = await this.kysely.selectFrom("uploads")
            .where("present", "=", DB_TRUE)
            .where(wb => {
                return wb.or(discard.map(item => wb.and([wb("id", "=", item[1]), wb("companyId", "=", item[0])])))
            })
            .select([
                "id",
                "companyId"
            ])
            .execute()
        if (present.length === 0) {
            console.log("[Sync/File] No present files to discard.")
            return {
                filesDeleted: 0
            }
        }
        console.log("[Sync/File] Discarding " + present.length + " files.", present)
        await this.store.deleteMulti(present.map(item => [item.companyId, idToBinary(item.id)] as const))
        return {
            filesDeleted: present.length
        }
    }

    async up(items: readonly [readonly [number, ID], Blob][]) {
        if (items.length === 0) {
            return []
        }
        const response = await this.hasura.query({
            upload: {
                __args: {
                    items: items.map(item => ({ companyId: item[0][0], id: printId(item[0][1]) }))
                },
                items: {
                    error: true,
                    url: true,
                },
            }
        })
        const responses = maybe(response.upload.items)
        if (responses.length !== items.length) {
            throw new Error("Received an incorrect number of S3 urls (" + items.length + ") for " + items.length + " items submitted.")
        }
        return await Promise.all(Ramda.zip(items, responses).map(async ([[id, blob], response]) => {
            if (response === null) {
                console.log("[Services/File] Received no URL but no error. This means the file is already present.")
                return Either.right(id)
            }
            const error = response.error
            const url = response.url
            if (error !== null) {
                return Either.left({
                    id,
                    reason: error
                })
            }
            else if (url !== null) {
                try {
                    if (blob.size === 0) {
                        throw new Error("File " + printId(id[1]) + " is 0 bytes.")
                    }
                    const request = { method: "PUT", body: blob }
                    const uploadResponse = await fetch(url, request)
                    console.log("[Services/File] Uploaded file " + printId(id[1]) + ".", request)
                    checkResponse(uploadResponse)
                    return Either.right(id)
                }
                catch (reason) {
                    if (!isTemporaryException(reason)) {
                        throw reason
                    }
                    return Either.left({
                        id,
                        reason
                    })
                }
            }
            else {
                throw new TypeError("Received no error and no URL for an upload item.")
            }
        }))
    }

}