import { ID, idToBinary, newId, printId } from "adapters/ids"
import { PICA_IDLE, UPLOADS_URL } from "config"
import Upload from "domain/entities/Upload"
import loadImage from "image-promise"
import { Insertable, QueryCreator } from "kysely"
import { RxJS } from "namespaces/RxJS"
import Pica from "pica"
import { readAsDataURL } from "promise-file-reader"
import { Observable } from "rxjs"
import { CompanySchema } from "services/db/Schema"
import FileStore from "services/store/FileStore"
import { UploadURLRequest, UploadURLs, uploadAndThumbnailUrls } from "shared/upload"
import { typeFromDataUrl } from "ui/camera/CameraModal"
import { MAX_PIXELS } from "ui/config"

/**
 * The status of an upload, meaning a reason why it is not available to show.
 */
export type UploadURLError = "offline" | "missing"

export interface Uploadable {

    readonly id?: ID | undefined
    readonly name: string
    readonly data: string | Blob
    //readonly dimensions?: readonly [number, number] | undefined

}

export function dataToType(data: string | Blob) {
    if (typeof data === "string") {
        return typeFromDataUrl(data)
    }
    return data.type
}

export async function dataToBlob(data: string | Blob) {
    if (typeof data === "string") {
        return await (await fetch(data)).blob()
    }
    return data
}

export type Uploaded = Readonly<Pick<Insertable<Upload>, "id" | "name" | "type" | "size" | "width" | "height">>

/**
 * This class is used to manage file uploads.
 */
export default class FileManager {

    /**
     * This is a singleton instance of the Pica library.
     */
    private static readonly Pica = Pica({
        idle: PICA_IDLE,
    })

    /**
     * Creates a new file manager.
     * @param companyId A company ID.
     * @param db A database query creator.
     * @param dexie A Dexie database.
     */
    constructor(private readonly companyId: number, private readonly db: QueryCreator<CompanySchema>, private readonly store: FileStore) {
    }

    /**
     * Gets the URLs for an upload.
     * @param request An upload meta.
     * @returns An observable of the URLs.
     */
    //TODO remove ID from uploadUrlRequest
    url(id: ID, request: UploadURLRequest, online: boolean): Observable<UploadURLs | UploadURLError> {
        if (online) {
            const found = uploadAndThumbnailUrls(UPLOADS_URL, this.companyId, printId(id), request)
            if (found !== undefined) {
                return RxJS.of(found)
            }
        }
        return RxJS.merge(this.store.get([this.companyId, idToBinary(id)]), this.store.observe([this.companyId, idToBinary(id)])).pipe(
            RxJS.map(url => {
                if (url === undefined) {
                    if (online) {
                        return "missing"
                    }
                    return "offline"
                }
                else {
                    return {
                        url
                    }
                }
            })
        )
    }

    /**
     * Creates a new upload.
     * @param name A file name.
     * @param data A blob or data URL.
     * @param width The width of the image.
     * @param height The height of the image.
     * @returns An upload.
     */
    async upload(uploads: Uploadable | readonly Uploadable[]) {
        const converted = await Promise.all(
            [uploads].flat().map(async upload => {
                const id = upload.id ?? newId()
                const type = dataToType(upload.data)
                if (type.startsWith("image/")) {
                    const dataUrl = typeof upload.data === "string" ? upload.data : await readAsDataURL(upload.data)
                    const image = await loadImage(dataUrl)
                    const type = typeFromDataUrl(dataUrl)
                    const scale = Math.sqrt(MAX_PIXELS / (image.width * image.height))
                    const ratio = Math.min(scale, 1)
                    const canvas = document.createElement("canvas")
                    canvas.width = ratio * image.width
                    canvas.height = ratio * image.height
                    console.log("[Services/File] Resizing with a ratio of " + ratio + " (" + canvas.width + "x" + canvas.height + ")...")
                    const resized = await FileManager.Pica.resize(image, canvas)
                    const blob = await FileManager.Pica.toBlob(resized, type)
                    return {
                        id,
                        name: upload.name,
                        data: blob,
                        dimensions: [canvas.width, canvas.height] as const,
                    }
                }
                else {
                    return {
                        id,
                        name: upload.name,
                        data: await dataToBlob(upload.data),
                        dimensions: undefined,
                    }
                }
            })
        )
        const uploaded = converted.map(upload => {
            return {
                id: upload.id,
                name: upload.name,
                type: upload.data.type,
                size: upload.data.size,
                width: upload.dimensions?.[0],
                height: upload.dimensions?.[1],
            }
        })
        //TODO clean failure if one of them already exists.
        await this.db.insertInto("uploads")
            .values(uploaded)
            .execute()
        try {
            await this.store.setMulti(converted.map(file => {
                return [
                    [
                        this.companyId,
                        idToBinary(file.id)
                    ],
                    file.data,
                ]
            }))
        }
        catch (e) {
            await this.db.deleteFrom("uploads").where("id", "in", converted.map(file => file.id)).execute()
            throw e
        }
        return uploaded
    }

}