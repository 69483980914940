import { view, lensProp, lens, pick, identity, defaultTo, set, lensIndex } from 'ramda';
import { useState } from 'react';
import { callOrGet } from 'value-or-factory';
import { buildErrors, descendErrors } from './errors.mjs';

class FormFieldImpl {
    from;
    // readonly path
    disabled;
    value;
    setValue;
    setValueAndCommit;
    blur;
    commit;
    focus;
    toggle;
    errors;
    selfErrors;
    hasErrors;
    hasSelfErrors;
    setErrors;
    pipe;
    prop;
    transform;
    narrow;
    or;
    props;
    static from(input) {
        return new FormFieldImpl(input);
    }
    constructor(from) {
        this.from = from;
        //  this.path = from.path
        this.disabled = from.disabled;
        this.value = from.value;
        this.setValue = from.setValue;
        this.blur = () => from.blur?.();
        this.commit = () => from.commit?.();
        this.focus = () => from.focus?.();
        this.toggle = (status) => {
            if (status === "focused") {
                from.focus?.();
            }
            else {
                from.blur?.();
            }
        };
        this.setValueAndCommit = (value) => {
            from.setValue(value);
            from.commit?.();
        };
        this.errors = from.errors;
        this.selfErrors = from.errors?.filter(_ => (_.path ?? []).length === 0);
        this.hasErrors = (from.errors?.length ?? 0) > 0;
        this.hasSelfErrors = (this.selfErrors?.length ?? 0) > 0;
        this.setErrors = from.setErrors;
        this.attachErrors = (inputErrors) => {
            this.setErrors(errors => {
                return [
                    ...errors ?? [],
                    ...buildErrors(inputErrors),
                ];
            });
        };
        this.pipe = this.doPipe.bind(this);
        this.prop = this.doProp.bind(this);
        this.transform = this.doTransform.bind(this);
        this.narrow = this.doNarrow.bind(this);
        this.or = this.doOr.bind(this);
        this.props = this.doProps.bind(this);
    }
    attachErrors;
    /*
    index(index: number): FormField<IndexOf<T>> {
        throw new Error("Method not implemented.")
    }

    private doIndex<N>(index: N) {
        if (Array.isArray(this.value)) {
            return this.doPipe(FormField.index<T, N>(index))
        }
        else {
            throw new Error("This is not an array field.")
        }
    }*/
    doTransform(to, back) {
        return this.doPipe(FormField.transform(to, back));
    }
    doOr(defaultValue) {
        return this.doNarrow(value => value ?? defaultValue);
    }
    doNarrow(to) {
        return this.doPipe(FormField.narrow(to));
    }
    /*
    private doAt<K extends IndexOf<T>>(key: K) {
        return this.doPipe(FormField.at(key))
    }
    */
    doProp(key) {
        return this.doPipe(FormField.prop(key));
    }
    doProps(keys) {
        return this.doPipe(FormField.props(keys));
    }
    doPipe(operator) {
        if (typeof operator === "function") {
            return this.doPipe({ operator });
        }
        const newValue = view(operator.operator, this.value);
        const newSetValue = (newValue) => {
            this.setValue(prev => {
                const newPrev = view(operator.operator, prev);
                //TODO change callOrGet to a functional style
                return set(operator.operator, callOrGet(newValue, newPrev), prev);
            });
        };
        const errors = descendErrors(this.errors ?? [], operator.path ?? []);
        const setErrors = (errors) => {
            this.setErrors(prevErrors => {
                const newErrors = buildErrors(callOrGet(errors, descendErrors(prevErrors, operator.path ?? [])));
                return newErrors.map(error => {
                    return {
                        ...error,
                        path: [...operator.path ?? [], ...error.path ?? []]
                    };
                });
            });
        };
        return FormFieldImpl.from({
            //path: [...this.path, ...this.path !== undefined ? this.path : []],
            value: newValue,
            setValue: newSetValue,
            blur: this.blur,
            commit: this.commit,
            focus: this.focus,
            disabled: this.disabled,
            errors,
            setErrors,
        });
    }
}
const undefinedIndex = lensIndex;
var FormField;
(function (FormField) {
    function prop(key) {
        return {
            operator: lensProp(key),
            path: [key],
        };
    }
    FormField.prop = prop;
    function props(keys) {
        return {
            operator: lens(object => pick(keys, object), (sub, object) => ({ ...object, ...sub })),
            path: [],
        };
    }
    FormField.props = props;
    function at(index) {
        return {
            operator: undefinedIndex(index),
            path: [index],
        };
    }
    FormField.at = at;
    function transform(to, back) {
        return lens(to, back);
    }
    FormField.transform = transform;
    function narrow(func) {
        return transform(func, identity);
    }
    FormField.narrow = narrow;
    function or(def) {
        return narrow(defaultTo(def));
    }
    FormField.or = or;
})(FormField || (FormField = {}));
function useField(value, setValue, options = {}) {
    const [errors, setRawErrors] = useState([]);
    const setErrors = (errors) => {
        setRawErrors(prev => {
            return buildErrors(callOrGet(errors, prev));
        });
    };
    return FormFieldImpl.from({
        ...options,
        value,
        setValue,
        errors,
        setErrors,
    });
}

export { FormField, FormFieldImpl, useField };
