import { isNotNil } from 'ramda';
import { useDeepCompareEffect } from './util.mjs';

const FORM_HOOKS = {
    blur: (context) => context.lastBlurred,
    change: (context) => context.lastChanged,
    commit: (context) => context.lastCommitted,
    focus: (context) => context.lastFocused,
    init: (context) => context.lastInitialized,
    afterSubmit: (context) => context.lastSubmitted,
    beforeSubmit: (context) => context.lastSubmitRequested,
    afterValidate: (context) => context.lastValidated,
    beforeValidate: (context) => context.lastValidateRequested,
};
const FORM_HOOK_KEYS = Object.keys(FORM_HOOKS);
/**
 * Attach a callback to a form hook.
 * @param form The form.
 * @param hooks Which hook to trigger on.
 * @param callback The callback to run.
 */
function useFormHook(form, hooks, action) {
    const value = [hooks].flat().map(hook => FORM_HOOKS[hook](form)).filter(isNotNil);
    useDeepCompareEffect(() => {
        if (value.length > 0) {
            action();
        }
    }, [
        hooks,
        value
    ]);
}

export { FORM_HOOKS, FORM_HOOK_KEYS, useFormHook };
