import { useAccount, useGlobal, useStore } from "adapters/hooks"
import { fromHack } from "adapters/rxjs"
import { PG, SYNTHETIC_DELAY } from "config"
import { RxJS } from "namespaces/RxJS"
import { useCallback, useMemo } from "react"
import DataManager from "services/DataManager"
import FileManager from "services/FileManager"
import { useLastSync } from "services/Settings"
import { CompanySchema, Schema } from "services/db/Schema"
import CompanyDefaultsPlugin from "services/db/kysely/CompanyDefaultsPlugin"
import CompanyDiscriminatorPlugin from "services/db/kysely/CompanyDiscriminatorPlugin"
import { ObservableKysely } from "services/db/kysely/ObservableKysely"
import { SAHWorkerDialect } from "services/db/kysely/WorkerKysely"
import { PGLiteDialect } from "services/db/kysely/pg"
import { SqliteConnection } from "services/db/sqlite/connection"
import { GatewayBasedSqliteConnection } from "services/db/sqlite/gateway"
import PDFConverter from "services/rendering/PDFConverter"
import Syncer from "services/sync/Syncer"
import * as GenQLSync from "shared/genql/sync"
import { isTemporaryException } from "shared/http"
import { errorToString, getRootCause } from "shared/misc"
import { TemplateRenderer } from "shared/services"
import { useAsyncTracker, useConstructor, useFactory } from "state-hooks"
import { useAuth } from "ui/auth/authorization"
import PrefixedStore from "ui/store/PrefixedStore"

export default interface Core {

    readonly root: ObservableKysely<unknown>
    readonly db: ObservableKysely<Schema>
    readonly pdfConverter: PDFConverter
    readonly hasura: GenQLSync.Client
    readonly renderer: TemplateRenderer
    readonly connection: SqliteConnection
    readonly dataManager: DataManager

}

export function useCompanyDb() {
    const auth = useAuth()
    const account = useAccount()
    return useFactory((userId, companyId, db) => {
        return db.withTables<CompanySchema>()
            .withPlugin(new CompanyDiscriminatorPlugin(companyId))
            .withPlugin(new CompanyDefaultsPlugin(companyId, userId))
    }, [
        auth.data.id,
        account.company.id,
        useDb<unknown>()
    ])
}
export function useFileManager() {
    const account = useAccount()
    const db = useCompanyDb()
    const fileStore = useUserFileStore()
    return useConstructor(FileManager, [account.company.id, db, fileStore])
}
export function useDataManager() {
    const global = useGlobal()
    const worker = useWorker()
    const auth = useAuth()
    const store = useUserStore(auth.data.id)
    return useConstructor(DataManager, [store, global, worker, auth.logOut, auth.data.id])
}
export function useUserStore(id: number) {
    const store = useStore()
    return useMemo(() => new PrefixedStore(store, id + "-"), [store])
}
export function useWorker() {
    return useGlobal().proxy
}
export function useSyncer() {
    const auth = useAuth()
    const db = useDb<unknown>()
    const [_lastSync, setLastSync] = useLastSync()
    const fileStore = useUserFileStore()

    /*
        const fs = DexieAsyncStore.selfManaging<readonly [number, ID], Blob>("blobs-" + user)
        //const db = new Kysely({ dialect: new SwitchingWorkerDialect(this, user.toString()) })
        const db = this.kysely(user.toString())
        try {
            return await new Syncer(db, fs, token).sync()
        }
        finally {
            await db.destroy()
        }
    */
    const tracker = useAsyncTracker()
    return useCallback(() => {
        tracker.run(async () => {
            try {
                const value = await new Syncer(db, fileStore, auth.data.token, auth.data.id).sync() //worker.sync(auth.data.id, auth.data.token)
                console.log("[Sync] Received statistics from worker.", value)
                setLastSync({
                    date: Date.now(),
                    status: "success",
                    value: Object.fromEntries(value)
                })
            }
            catch (e) {
                if (isTemporaryException(e)) {
                    setLastSync({
                        date: Date.now(),
                        status: "failure",
                        errorType: "network",
                        error: errorToString(getRootCause(e))
                    })
                }
                else {
                    setLastSync({
                        date: Date.now(),
                        status: "failure",
                        errorType: "exception",
                        error: errorToString(getRootCause(e))
                    })
                }
            }
        })
    }, [
        tracker,
        db,
        fileStore,
        setLastSync,
        auth.data.token,
        //worker,
    ])
}
export function useUserFileStore() {
    const global = useGlobal()
    const auth = useAuth()
    return useMemo(() => global.userFileStore(auth.data.id), [global, auth.data.id]) //TODO this is still iffy performance wise - it has to open dexie. look at making this stateful on global and then having prefixed stores under it or something
}
export function useDb<T = Schema>() {
    const auth = useAuth()
    const worker = useWorker()
    const global = useGlobal()
    return useFactory((global, id) => {
        const opener = (autoReconnect: boolean) => {
            return fromHack(worker.test()).pipe(
                RxJS.map(() => {
                    return async () => new GatewayBasedSqliteConnection(worker, await worker.open(id.toString()))
                })
            )
            /*
            return RxJS.of(void 0).pipe(
                RxJS.map(() => {
                    return async () => new GatewayBasedSqliteConnection(worker, await worker.open(id.toString()))
                })
            )*/
        }
        if (PG) {
            return new ObservableKysely<T>({ dialect: new PGLiteDialect(global.pg) })
        }
        else {
            return new ObservableKysely<T>({ dialect: new SAHWorkerDialect(opener, o => o.pipe(RxJS.delay(SYNTHETIC_DELAY))) })
        }
    }, [
        global,
        auth.data.id
    ])
}
