// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
const BulkPrintOutput_possibleTypes = ['BulkPrintOutput'];
export const isBulkPrintOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isBulkPrintOutput"');
    return BulkPrintOutput_possibleTypes.includes(obj.__typename);
};
const PrintOutput_possibleTypes = ['PrintOutput'];
export const isPrintOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isPrintOutput"');
    return PrintOutput_possibleTypes.includes(obj.__typename);
};
const Time_possibleTypes = ['Time'];
export const isTime = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTime"');
    return Time_possibleTypes.includes(obj.__typename);
};
const TokenData_possibleTypes = ['TokenData'];
export const isTokenData = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTokenData"');
    return TokenData_possibleTypes.includes(obj.__typename);
};
const UploadTemporaryOutput_possibleTypes = ['UploadTemporaryOutput'];
export const isUploadTemporaryOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadTemporaryOutput"');
    return UploadTemporaryOutput_possibleTypes.includes(obj.__typename);
};
const Users_possibleTypes = ['Users'];
export const isUsers = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsers"');
    return Users_possibleTypes.includes(obj.__typename);
};
const UsersAggregate_possibleTypes = ['UsersAggregate'];
export const isUsersAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAggregate"');
    return UsersAggregate_possibleTypes.includes(obj.__typename);
};
const UsersAggregateFields_possibleTypes = ['UsersAggregateFields'];
export const isUsersAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAggregateFields"');
    return UsersAggregateFields_possibleTypes.includes(obj.__typename);
};
const UsersAvgFields_possibleTypes = ['UsersAvgFields'];
export const isUsersAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAvgFields"');
    return UsersAvgFields_possibleTypes.includes(obj.__typename);
};
const UsersMaxFields_possibleTypes = ['UsersMaxFields'];
export const isUsersMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersMaxFields"');
    return UsersMaxFields_possibleTypes.includes(obj.__typename);
};
const UsersMinFields_possibleTypes = ['UsersMinFields'];
export const isUsersMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersMinFields"');
    return UsersMinFields_possibleTypes.includes(obj.__typename);
};
const UsersMutationResponse_possibleTypes = ['UsersMutationResponse'];
export const isUsersMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersMutationResponse"');
    return UsersMutationResponse_possibleTypes.includes(obj.__typename);
};
const UsersStddevFields_possibleTypes = ['UsersStddevFields'];
export const isUsersStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevFields"');
    return UsersStddevFields_possibleTypes.includes(obj.__typename);
};
const UsersStddevPopFields_possibleTypes = ['UsersStddevPopFields'];
export const isUsersStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevPopFields"');
    return UsersStddevPopFields_possibleTypes.includes(obj.__typename);
};
const UsersStddevSampFields_possibleTypes = ['UsersStddevSampFields'];
export const isUsersStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevSampFields"');
    return UsersStddevSampFields_possibleTypes.includes(obj.__typename);
};
const UsersSumFields_possibleTypes = ['UsersSumFields'];
export const isUsersSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersSumFields"');
    return UsersSumFields_possibleTypes.includes(obj.__typename);
};
const UsersVarPopFields_possibleTypes = ['UsersVarPopFields'];
export const isUsersVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarPopFields"');
    return UsersVarPopFields_possibleTypes.includes(obj.__typename);
};
const UsersVarSampFields_possibleTypes = ['UsersVarSampFields'];
export const isUsersVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarSampFields"');
    return UsersVarSampFields_possibleTypes.includes(obj.__typename);
};
const UsersVarianceFields_possibleTypes = ['UsersVarianceFields'];
export const isUsersVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarianceFields"');
    return UsersVarianceFields_possibleTypes.includes(obj.__typename);
};
const mutation_root_possibleTypes = ['mutation_root'];
export const ismutation_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "ismutation_root"');
    return mutation_root_possibleTypes.includes(obj.__typename);
};
const query_root_possibleTypes = ['query_root'];
export const isquery_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isquery_root"');
    return query_root_possibleTypes.includes(obj.__typename);
};
const subscription_root_possibleTypes = ['subscription_root'];
export const issubscription_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "issubscription_root"');
    return subscription_root_possibleTypes.includes(obj.__typename);
};
export const enumCursorOrdering = {
    ASC: 'ASC',
    DESC: 'DESC'
};
export const enumOrderBy = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST'
};
export const enumPaging = {
    AUTO_HEIGHT: 'AUTO_HEIGHT',
    PAGED: 'PAGED',
    SCALE_TO_FIT: 'SCALE_TO_FIT'
};
export const enumTimeSelectColumn = {
    now: 'now'
};
export const enumUsersSelectColumn = {
    acceptedTos: 'acceptedTos',
    admin: 'admin',
    archived: 'archived',
    createdOn: 'createdOn',
    developer: 'developer',
    emailAddress: 'emailAddress',
    id: 'id',
    name: 'name',
    password: 'password'
};
